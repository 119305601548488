import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomAxios from '../../customComponents/customAxios'
import { addWebinarToCatalog, getWebinarInCatalogs, removeWebinarFromCatalog } from '../../resources/constants'
import { Webinar } from '../../types/data'
import { Reducers, ReducerUser } from '../../types/redurcers'
import MaterialTable from 'material-table'
import { locale_itIT } from '../../utility/tableLocale'
import { setCatalogs } from '../../store/actions/thunk_actions'

interface Props {
    location: any
}

const WebinarsDetailPage: React.FC<Props> = (props) => {
    const webinar: Webinar = props.location.state.webinar
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [webinarsInCatalogs, setWebinarsInCatalogs] = useState([])
    const dispatch = useDispatch()

    const fetchWebinarInCatalogs = async (idUser: string, idWebinar: number) => {
        const webinarInCatalogsFetch = await CustomAxios.get(getWebinarInCatalogs(idUser, idWebinar)).then((response) => response.data.webinarincatalogs)
        setWebinarsInCatalogs(webinarInCatalogsFetch)
    }

    const handleLink = (rowData: any) => {
        if (rowData.is_present) {
            CustomAxios.get(removeWebinarFromCatalog(user.id, webinar.idwebinar, rowData.id)).then(() => {
                fetchWebinarInCatalogs(user.id, webinar.idwebinar)
                dispatch(setCatalogs(user.id))
            })
        } else {
            CustomAxios.get(addWebinarToCatalog(user.id, webinar.idwebinar, rowData.id)).then(() => {
                fetchWebinarInCatalogs(user.id, webinar.idwebinar)
                dispatch(setCatalogs(user.id))
            })
        }
    }

    useEffect(() => {
        fetchWebinarInCatalogs(user.id, webinar.idwebinar)
    }, [])

    const renderCatalog = () => {
        return webinarsInCatalogs.map((catalog: any) => {
            return {
                id: catalog.idcatalogo,
                catalog: catalog.titolo,
                is_present: catalog.presente
            }
        })
    }

    return (
        <div className="webinarsDetailPage">
            <div className="webinarsTitleSection">
                <h3>Webinar</h3>
                <h2>{webinar.subject}</h2>
                <p>Account {webinar.account}</p>
            </div>
            <div style={{ clear: 'both' }}></div>
            <p className="descriptionWebinar">{webinar.description}</p>
            <div className="webinarsDetailSection">
                <p>Seleziona in quali cataloghi vuoi inserire questo webinar</p>
                <MaterialTable
                    title=""
                    columns={[{ title: 'Catalogo', field: 'catalog' }]}
                    data={renderCatalog()}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        exportButton: false,
                        showTitle: false,
                        header: false,
                        rowStyle: (rowData) => ({
                            backgroundColor: rowData.is_present ? '#ff00ff' : '#FFF',
                            color: rowData.is_present ? '#fff' : '#333'
                        })
                    }}
                    //@ts-ignore
                    actions={[
                        (rowData: any) => ({
                            icon: rowData.is_present ? 'link_off' : 'link_on',
                            iconProps: {
                                color: rowData.is_present ? 'primary' : 'disabled'
                            },
                            tooltip: rowData.is_present ? 'Scollega' : 'Collega',
                            onClick: (event: any, rowData: any) => handleLink(rowData)
                        })
                    ]}
                />
            </div>
        </div>
    )
}

export default WebinarsDetailPage
