import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { defaultProfileImage } from '../../resources/constants'
import { Reducers, ReducerUser } from '../../types/redurcers'
import './Profile.css'
import ProfileView from './ProfileView'
import TimelineView from './TimelineView'

const ProfilePage: React.FC = () => {
    const [currentTab, setCurrentTab] = useState(0)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)

    const renderTab = () => {
        switch (currentTab) {
            case 0:
                return <TimelineView />
            case 1:
                return <ProfileView />
        }
    }

    return (
        <div className="profilePage">
            <div className="headerProfile">
                <div className="cardProfile"></div>
                <div className="profileName">
                    <img
                        src={user.avatar !== undefined && user.avatar !== null && user.avatar !== '' ? user.avatar : defaultProfileImage}
                        className="profileImage"
                    />
                    <p className="profileNameSurname">
                        {user.firstName !== undefined && user.firstName.toUpperCase()} {user.lastName !== undefined && user.lastName.toUpperCase()}
                    </p>
                    <p className="profileEmail">{user.email}</p>
                </div>
                <div style={{ width: 'inherit', paddingTop: 80, paddingBottom: 80 }}>
                    <div className="menuItemProfile">
                        <li className={currentTab == 0 ? 'active' : ''}>
                            <a onClick={() => setCurrentTab(0)} className="menuLinkProfile">
                                Timeline
                            </a>
                        </li>
                    </div>
                    <div className="menuItemProfile">
                        <li className={currentTab == 1 ? 'active' : ''}>
                            <a onClick={() => setCurrentTab(1)} className="menuLinkProfile">
                                Profilo
                            </a>
                        </li>
                    </div>
                </div>
            </div>
            {renderTab()}
        </div>
    )
}

export default ProfilePage
