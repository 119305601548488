import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { config } from '../configs/firebase.config'
import 'firebase/storage'

console.log('### Initialize Firebase')

firebase.initializeApp(config)
firebase.firestore()

export const storage = firebase.storage()
export default firebase
