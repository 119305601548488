export const config = {
    apiKey: 'AIzaSyBzVR8m0WjCA2OGfg7IJs1M1KZw0m9zrTE',
    authDomain: 'intelligentcontentprovid-7edf2.firebaseapp.com',
    databaseURL: 'https://intelligentcontentprovid-7edf2.firebaseio.com',
    projectId: 'intelligentcontentprovid-7edf2',
    storageBucket: 'intelligentcontentprovid-7edf2.appspot.com',
    messagingSenderId: '924683563178',
    appId: '1:924683563178:web:3504c83e37c86572fb20d3',
    measurementId: 'G-SPFSV59QHK'
}
