import React from 'react'

export const columns = [
    {
        title: 'Creator',
        field: 'account',
        cellStyle: { width: 150 }
    },
    {
        title: 'Titolo',
        field: 'subject'
    },
    {
        title: 'Descrizione',
        field: 'description'
    },
    {
        title: 'Relatori',
        field: 'relators',
        // eslint-disable-next-line react/display-name
        render: (rowData: any) => {
            return <pre>{rowData.relators}</pre>
        }
    },
    {
        title: 'Prossimi appuntamenti',
        field: 'times',
        // eslint-disable-next-line react/display-name
        render: (rowData: any) => {
            return <pre>{rowData.times}</pre>
        }
    }
]
