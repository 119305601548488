import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerUser } from '../../types/redurcers'
import './Webinar.css'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Catalogs, Webinar } from '../../types/data'
import MaterialTable from 'material-table'
import CustomAxios from '../../customComponents/customAxios'
import { createCatalog, deleteCatalog, removeWebinarFromCatalog, setTrashWebinarState } from '../../resources/constants'
import { setCatalogs, setWebinars } from '../../store/actions/thunk_actions'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

const CatalogPage: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [visibleDeleteCatalogModal, setVisibleDeleteCatalogModal] = useState(false)
    const [visibleDeleteWebinarModal, setVisibleDeleteWebinarModal] = useState(false)
    const [selectedCatalog, setSelectedCatalog] = useState<Catalogs>()
    const [selectedWebinar, setSelectedWebinar] = useState()
    const [visibleAddCatalogModal, setVisibleAddCatalogModal] = useState(false)
    const dispatch = useDispatch()
    const nameInput = useRef(null)

    const handleLink = async (idwebinar: number, idcatalogo: number) => {
        await CustomAxios.get(removeWebinarFromCatalog(user.id, idwebinar, idcatalogo)).then(() => {
            dispatch(setCatalogs(user.id))
        })
    }

    const handleDeleteWebinar = async (id: number) => {
        await CustomAxios.get(setTrashWebinarState(user.id, id)).then(async () => {
            await dispatch(setWebinars(user.id))
        })
        setVisibleDeleteWebinarModal(false)
        setSelectedWebinar(undefined)
    }

    const renderWebinarRow = (webinars: Webinar[], idcatalogo: number) => {
        return webinars.map((webinar: Webinar) => {
            return {
                id: webinar.ID,
                webinar: webinar.subject,
                attivo: webinar.attivo,
                idwebinar: webinar.idwebinar,
                idcatalogo: idcatalogo
            }
        })
    }

    const renderCatalogsList = (catalog: Catalogs) => {
        return (
            <Accordion key={catalog.idcatalogo.toString()}>
                <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                    <p>
                        <span className="webinarListLength">{catalog.webinar.length}</span>
                        {catalog.catalogo}
                    </p>
                    <p className="trashIcon">
                        <FontAwesomeIcon
                            icon={faTrash}
                            color="#f44336"
                            onClick={() => {
                                setVisibleDeleteCatalogModal(true)
                                setSelectedCatalog(catalog)
                            }}
                        />
                    </p>
                </AccordionSummary>
                <AccordionDetails>
                    {catalog.webinar.length > 0 ? (
                        <MaterialTable
                            columns={[{ title: 'Webinar', field: 'webinar' }]}
                            data={renderWebinarRow(catalog.webinar, catalog.idcatalogo)}
                            options={{
                                showTitle: false,
                                header: false,
                                actionsColumnIndex: -1
                            }}
                            actions={[
                                () => ({
                                    icon: 'link_off',
                                    iconProps: {
                                        color: 'primary'
                                    },
                                    tooltip: 'Scollega',
                                    onClick: (event: any, rowData: any) => handleLink(rowData.idwebinar, rowData.idcatalogo)
                                }),
                                () => ({
                                    icon: 'delete',
                                    iconProps: {
                                        color: 'error'
                                    },
                                    tooltip: 'Elimina',
                                    onClick: (event: any, rowData: any) => {
                                        setVisibleDeleteWebinarModal(true)
                                        setSelectedWebinar(rowData)
                                    }
                                })
                            ]}
                        />
                    ) : (
                        <p>Nessun webinar associato a questo catalogo</p>
                    )}
                </AccordionDetails>
            </Accordion>
        )
    }

    const handleCreateCatalog = async (name: string) => {
        //console.log(createCatalog(user.id, name))
        await CustomAxios.get(createCatalog(user.id, name)).then(async () => {
            await dispatch(setCatalogs(user.id))
        })
        setVisibleAddCatalogModal(false)
    }

    const handleRemoveCatalog = async (catalogId: number) => {
        await CustomAxios.get(deleteCatalog(user.id, catalogId)).then(async () => {
            await dispatch(setCatalogs(user.id))
        })
        setVisibleDeleteCatalogModal(false)
        setSelectedCatalog(undefined)
    }

    const renderModalRemoveWebinar = () => {
        return (
            <Dialog open={visibleDeleteWebinarModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi cancellare questo webinar?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Stai eliminando il webinar{' '}
                        {
                            //@ts-ignore
                            selectedWebinar?.webinar
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleDeleteWebinarModal(false)
                                setSelectedWebinar(undefined)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={() => {
                                //@ts-ignore
                                handleDeleteWebinar(selectedWebinar?.idwebinar)
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const renderModalRemoveCatalog = () => {
        return (
            <Dialog open={visibleDeleteCatalogModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi cancellare questo catalogo?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Stai eliminando il catalogo {selectedCatalog?.catalogo}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleDeleteCatalogModal(false)
                                setSelectedCatalog(undefined)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={() => {
                                //@ts-ignore
                                handleRemoveCatalog(selectedCatalog?.idcatalogo)
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const renderModalAddCatalog = () => {
        return (
            <Dialog fullWidth={true} open={visibleAddCatalogModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Aggiungi un nuovo catalogo</DialogTitle>
                <DialogContent>
                    <div>
                        <input ref={nameInput} type="text" id="newCatalogTitle" name="basicinput" placeholder="Inserisci il nome di questo catalogo" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleAddCatalogModal(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={() => {
                                //@ts-ignore
                                handleCreateCatalog(nameInput.current.value)
                            }}
                            color="secondary"
                        >
                            Inserisci
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <div className="catalogsTablePage">
            <div className="catalogsTitleSection">
                <h2>Elenco dei cataloghi</h2>
                <p>I tuoi cataloghi</p>
            </div>
            <a
                className="addCatalogsButton"
                onClick={() => {
                    setVisibleAddCatalogModal(true)
                }}
            >
                Aggiungi catalogo
            </a>
            <div style={{ clear: 'both' }}></div>
            <div className="catalogsTableSection">
                <p>Controlla quali webinar sono assegnati ai cataloghi.</p>
                {data.catalogs.map((catalog) => renderCatalogsList(catalog))}
            </div>
            {renderModalRemoveCatalog()}
            {renderModalRemoveWebinar()}
            {renderModalAddCatalog()}
        </div>
    )
}

export default CatalogPage
