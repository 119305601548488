import { PlayerContent } from '../../types/player'
import { ReduxAction } from '../../types/redurcers'

export enum PLAYER_ACTIONS {
    SET_CONTENT_PLAYER = 'graphicActions/setContentPlayer'
}

export const setContentPlayer: ReduxAction<PlayerContent> = (content) => {
    return {
        type: PLAYER_ACTIONS.SET_CONTENT_PLAYER,
        payload: content
    }
}
