import { Feed, SpreakerShow } from './../../types/data'
import { setContentsData, setTagsData, setCategoriesData, setGuestsData, setWebinarsData, setCatalogsData, setSpreakerShowsData, setFeedsData } from './data'
import { setServices, setUser, setHasSpreaker } from './user'
import {
    getContents,
    getSubscriptions,
    getTags,
    getCategories,
    getGuests,
    getViewDBWebinar,
    getAllCatalogs,
    getSpreakerUserShow,
    refreshSpreakerToken,
    getExternalFeeds
} from './../../resources/constants'
import CustomAxios from '../../customComponents/customAxios'

import { Reducers } from './../../types/redurcers'
import Services from '../../utility/Services'
import { Contents, Tags, Categories, Guests, Webinar, Catalogs } from '../../types/data'
import { User } from '../../types/user'

export const setSubscriptions: any = (id: string) => async (dispatch: (any: any) => void) => {
    const subscriptionsFetch = await CustomAxios.get(getSubscriptions(id)).then((response) => response.data.subscriptions)
    const hasSpreaker = subscriptionsFetch.find((sub: any) => sub.spreakerConnector == true)
    if (hasSpreaker !== undefined) {
        dispatch(setHasSpreaker(true))
    }
    const arrayServices = subscriptionsFetch.map((subserv: any) => {
        return subserv.id
    })
    await dispatch(setServices(arrayServices))
}

// export const setServicesData: any = () => async (dispatch: (any: any) => void) => {
//     const services = await CustomAxios.get(getServices()).then((response) => response.data.services)
//     console.log("CI PASSO")
//     await dispatch(setServicesSubscriptions(services))
// }

export const setContents: any = (id: string) => async (dispatch: (any: any) => void) => {
    const contentsFetch: Contents[] = await CustomAxios.get(getContents(id)).then((response) => response.data.contents)
    await dispatch(setContentsData(contentsFetch))
}

export const setFeeds: any = (id: string) => async (dispatch: (any: any) => void) => {
    const feedFetch: Feed[] = await CustomAxios.get(getExternalFeeds(id)).then((response) => response.data.feeds)
    await dispatch(setFeedsData(feedFetch))
}

export const setWebinars: any = (id: string) => async (dispatch: (any: any) => void) => {
    const webinarsFetch: Webinar = await CustomAxios.get(getViewDBWebinar(id)).then((response) => response.data.items)
    await dispatch(setWebinarsData(webinarsFetch))
}

export const setTags: any = () => async (dispatch: (any: any) => void) => {
    const tagsFetch: Tags = await CustomAxios.get(getTags).then((response) => response.data.tags)
    await dispatch(setTagsData(tagsFetch))
}

export const setCategories: any = () => async (dispatch: (any: any) => void) => {
    const categoriesFetch: Categories = await CustomAxios.get(getCategories).then((response) => response.data.categories)
    await dispatch(setCategoriesData(categoriesFetch))
}

export const setGuests: any = () => async (dispatch: (any: any) => void) => {
    const guestsFetch: Guests = await CustomAxios.get(getGuests).then((response) => response.data.guests)
    await dispatch(setGuestsData(guestsFetch))
}

export const setCatalogs: any = (id: string) => async (dispatch: (any: any) => void) => {
    const catalogsFetch: Catalogs[] = await CustomAxios.get(getAllCatalogs(id)).then((response) => response.data.catalogs)
    await dispatch(setCatalogsData(catalogsFetch))
}

export const setSpreakerShows: any = (id: string) => async (dispatch: (any: any) => void) => {
    const showsFetch: SpreakerShow[] = await CustomAxios.get(getSpreakerUserShow(id)).then((response) => response.data.shows)
    await CustomAxios.get(refreshSpreakerToken(id)).then(() => null)
    dispatch(setSpreakerShowsData(showsFetch))
}

export const setDatas: any = () => async (dispatch: (any: any) => void, getState: () => Reducers) => {
    try {
        const user = getState().user

        await dispatch(setSubscriptions(user.id))
        await dispatch(setTags())
        await dispatch(setCategories())
        await dispatch(setGuests())
        await dispatch(setFeeds(user.id))

        if (user.services.includes(Services.Contents)) {
            await dispatch(setContents(user.id))
        }
        if (user.hasSpreaker) {
            await dispatch(setSpreakerShows(user.id))
        }
        if (user.services.includes(Services.Webinar)) {
            await dispatch(setWebinars(user.id))
            await dispatch(setCatalogs(user.id))
            await dispatch(setCatalogs(user.id))
        }
    } catch (e) {
        console.log('ERRORE', e)
    }
}

export const setUserAndFetch: any = (user: User) => async (dispatch: (any: any) => void) => {
    await dispatch(setUser(user))
    dispatch(setDatas())
}
