import React from 'react'
import './Sidebar.css'
import SideMenu from './Sidemenu/Sidemenu'
import { Layers } from 'react-feather'
import { servicesMenu } from './menu/menu'

const Sidebar: React.FC = () => {
    return (
        <div className="app-sidebar">
            <div className="logo">
                <img src="https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/Peperoni-logo-white.svg" />
            </div>
            <SideMenu name="Servizi" badge={<Layers size={20} />} menuItems={servicesMenu} />
        </div>
    )
}

export default Sidebar
