import { useRef, useEffect } from 'react'
import { BUCKET_NAME, s3 } from '../resources/constants'
import { Categories, Tags, Guests, SpreakerShow } from './../types/data'

export const fromCategoriesToString = (categories: Categories[]): string => {
    let categoriesString = ''
    if (categories.length > 0) {
        categories.forEach((category, index) => {
            if (index + 1 == categories.length) {
                categoriesString += category.value
            } else {
                categoriesString += category.value + ', '
            }
        })
    }
    return categoriesString
}

export const fromTagsToString = (tags: Tags[]): string => {
    let tagsString = ''
    if (tags.length > 0) {
        tags.forEach((tag, index) => {
            if (index + 1 == tags.length) {
                tagsString += tag.value
            } else {
                tagsString += tag.value + ', '
            }
        })
    }
    return tagsString
}

export const fromGuestToString = (guests: Guests[]): string => {
    let guestsString = ''
    if (guests.length > 0) {
        guests.forEach((guest, index) => {
            if (index + 1 == guests.length) {
                guestsString += guest.firstName !== null ? guest.firstName + (guest.lastName !== '' ? ' ' + guest.lastName : '') : guest.completeName
            } else {
                guestsString +=
                    guest.firstName !== null ? guest.firstName + (guest.lastName !== '' ? ' ' + guest.lastName : '') + ', ' : guest.completeName + ', '
            }
        })
    }
    return guestsString
}

export const categoriesForSelect = (categories: any) => {
    if (categories == []) {
        return
    }
    const arrayFormatted = categories.map((category: any) => {
        return { id: category.id, value: category.id, label: category.value }
    })
    return arrayFormatted
}

export const showsForSelect = (shows: SpreakerShow[]) => {
    if (shows == []) {
        return
    }
    const arrayFormatted = shows.map((show) => {
        return { id: show.showId, value: show.showId, label: show.title }
    })
    return arrayFormatted
}

export const tagsForSelect = (tags: Tags[]) => {
    if (tags == []) {
        return
    }
    const arrayFormatted = tags.map((tag) => {
        return { id: tag.id, value: tag.id, label: tag.value }
    })
    return arrayFormatted
}

export const guestForSelect = (guests: Guests[]) => {
    if (guests == []) {
        return
    }
    const arrayFormatted = guests.map((guest) => {
        return { id: guest.id, value: guest.id, label: guest.completeName }
    })
    return arrayFormatted
}

export const formatSeconds = (seconds: number): string => {
    const pad = (string: number) => {
        return ('0' + string).slice(-2)
    }
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
        return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
}

export const uploadFile = (file: any, id: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const params = {
            Bucket: BUCKET_NAME + '/' + id,
            Key: file.name,
            Body: file,
            ContentType: file.type,
            ACL: 'public-read'
        }
        s3.upload(params, function (err: any, data: any) {
            if (err) {
                reject()
                throw err
            }
            resolve(data.Location)
        })
    })
}

/**
 * Mantiene lo stato precendente del valore passato
 * @param value any
 */
export const usePrevious = (value: any): any => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}
