import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Contents } from '../../types/data'
import { ReducerData, Reducers, ReducerUser } from '../../types/redurcers'
import { categoriesForSelect, guestForSelect, tagsForSelect, uploadFile } from '../../utility/functions'
import './Contents.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlay, faEye } from '@fortawesome/free-solid-svg-icons'
import CustomAxios from '../../customComponents/customAxios'
import { createContent, updateContent, createCategory, createTag, createGuest } from '../../resources/constants'
import { setContents, setCategories, setTags, setGuests } from '../../store/actions/thunk_actions'
import EditStatus from '../../utility/EditStatus'
import { ContentType } from '../../utility/ContentType'
import ArchiveModal from '../../components/modals/ArchiveModals'
import { dismissToast, ToastLoading, ToastSuccess } from '../../utility/Toast'

const audioCover = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/audioCover.png'
const videoCover = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/videoCover.png'
const textCover = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/textCover.png'
const imageCover = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/imageCover.png'

interface Props {
    location: any
    history: any
}

const ContentsDetailPage: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [updateStatus, setUpdateStatus] = useState('')
    const [currentContent, setCurrentContent] = useState<Contents>(props.location.state.content)
    const [title, setTitle] = useState(currentContent.title)
    const [description, setDescription] = useState(currentContent.description)
    const [txt, setTxt] = useState(currentContent.txt)
    const [categoriesLocal, setCategoriesLocal] = useState(currentContent.categories)
    const [tagsLocal, setTagsLocal] = useState(currentContent.tags)
    const [guestsLocal, setGuestsLocal] = useState(currentContent.guests)
    const [audioUrl, setAudioUrl] = useState(currentContent.audioUrl)
    const [videoUrl, setVideoUrl] = useState(currentContent.videoUrl)
    const [txtUrl, setTxtUrl] = useState(currentContent.txtUrl)
    const [imgUrl, setImgUrl] = useState(currentContent.imgUrl)
    const [changed, setChanged] = useState(false)
    const [isNew, setIsNew] = useState(props.location.state.isNew)
    const [visibleModalArchive, setVisibleModalArchive] = useState(false)
    const [typeArchive, setTypeArchive] = useState<ContentType>()
    const audioArchive = data.contents.filter((content) => content.audioUrl !== null)
    const videoArchive = data.contents.filter((content) => content.videoUrl !== null)
    const txtArchive = data.contents.filter((content) => content.txtUrl !== null)
    const imgArchive = data.contents.filter((content) => content.imgUrl !== null)
    const dispatch = useDispatch()

    const handleFileAudioChange = (event: any) => {
        const file = event.target.files[0]
        if (file == undefined) {
            return
        }
        setAudioUrl(file)
        setChanged(true)
    }

    const handleFileVideoChange = (event: any) => {
        const file = event.target.files[0]
        if (file == undefined) {
            return
        }
        setVideoUrl(file)
        setChanged(true)
    }

    const handleFileTextChange = (event: any) => {
        const file = event.target.files[0]
        if (file == undefined) {
            return
        }
        setTxtUrl(file)
        setChanged(true)
    }

    const handleFileImageChange = (event: any) => {
        const file = event.target.files[0]
        if (file == undefined) {
            return
        }
        setImgUrl(file)
        setChanged(true)
    }

    const handleEdit = async () => {
        if (!changed || updateStatus == EditStatus.PENDING) {
            return
        }
        setUpdateStatus(EditStatus.PENDING)
        ToastLoading('⌛ Sto aggiornando')

        let audioUploaded = undefined
        if (
            audioUrl !== null &&
            audioUrl !== undefined &&
            audioUrl !== currentContent.audioUrl &&
            audioArchive.find((content) => content.audioUrl == audioUrl) == undefined &&
            audioUrl !== ''
        ) {
            audioUploaded = await uploadFile(audioUrl, user.id)
        } else {
            audioUploaded = audioUrl
        }
        let videoUploaded = undefined
        if (
            videoUrl !== null &&
            videoUrl !== undefined &&
            videoUrl !== currentContent.videoUrl &&
            videoArchive.find((content) => content.videoUrl == videoUrl) == undefined &&
            videoUrl !== ''
        ) {
            videoUploaded = await uploadFile(videoUrl, user.id)
        } else {
            videoUploaded = videoUrl
        }

        let txtUploaded = undefined
        if (
            txtUrl !== null &&
            txtUrl !== undefined &&
            txtUrl !== currentContent.txtUrl &&
            txtArchive.find((content) => content.txtUrl == txtUrl) == undefined &&
            txtUrl !== ''
        ) {
            txtUploaded = await uploadFile(txtUrl, user.id)
        } else {
            txtUploaded = txtUrl
        }

        let imgUploaded = undefined
        if (
            imgUrl !== null &&
            imgUrl !== undefined &&
            imgUrl !== currentContent.imgUrl &&
            imgArchive.find((content) => content.imgUrl == imgUrl) == undefined &&
            imgUrl !== ''
        ) {
            imgUploaded = await uploadFile(imgUrl, user.id)
        } else {
            imgUploaded = imgUrl
        }

        const categoriesIds =
            categoriesLocal.length > 0
                ? categoriesLocal.map((e) => {
                      return e.id
                  })
                : []
        const tagsIds =
            tagsLocal.length > 0
                ? tagsLocal.map((e) => {
                      return e.id
                  })
                : []
        const guestsIds =
            guestsLocal.length > 0
                ? guestsLocal.map((e) => {
                      return e.id
                  })
                : []

        const contentToUpload = {
            owner: user.id,
            id: currentContent.id,
            title: title,
            description: description,
            txt: txt,
            categories: categoriesIds,
            tags: tagsIds,
            guests: guestsIds,
            audio_url: audioUploaded !== '' ? audioUploaded : null,
            video_url: videoUploaded !== '' ? videoUploaded : null,
            txt_url: txtUploaded !== '' ? txtUploaded : null,
            img_url: imgUploaded !== '' ? imgUploaded : null,
            spreaker_episode_id: currentContent.spreakerEpisodeId
        }

        try {
            if (currentContent.id == undefined || currentContent.id == -1 || isNew) {
                await CustomAxios.post(createContent(user.id), contentToUpload).then(async (response) => {
                    await dispatch(setContents(user.id))
                    console.log(data.contents)
                    setIsNew(false)
                    const newCurrentContent = {
                        id: response.data.contents,
                        title: contentToUpload.title,
                        description: contentToUpload.description,
                        audio_url: contentToUpload.audio_url,
                        video_url: contentToUpload.video_url,
                        txt: contentToUpload.txt,
                        txt_url: contentToUpload.txt_url,
                        img_url: contentToUpload.img_url,
                        created_at: new Date(),
                        updated_at: new Date(),
                        tags: contentToUpload.tags,
                        categories: contentToUpload.categories,
                        guests: contentToUpload.categories,
                        language: '',
                        is_public: true,
                        spreaker_episode_id: ''
                    }
                    //@ts-ignore
                    setCurrentContent(newCurrentContent)
                    setUpdateStatus(EditStatus.SUCCESS)
                    dismissToast()
                    ToastSuccess('🚀 Contenuto pubblicato')
                    props.history.push('/contents/')
                })
            } else {
                await CustomAxios.put(updateContent(user.id, currentContent.id), contentToUpload).then(async () => {
                    await dispatch(setContents(user.id))
                    const newCurrentContent = {
                        id: currentContent.id,
                        title: contentToUpload.title,
                        description: contentToUpload.description,
                        audio_url: contentToUpload.audio_url,
                        video_url: contentToUpload.video_url,
                        txt: contentToUpload.txt,
                        txt_url: contentToUpload.txt_url,
                        img_url: contentToUpload.img_url,
                        created_at: currentContent.createdAt,
                        updated_at: new Date(),
                        tags: contentToUpload.tags,
                        categories: contentToUpload.categories,
                        guests: contentToUpload.categories,
                        language: currentContent.language,
                        is_public: true,
                        spreaker_episode_id: currentContent.spreakerEpisodeId
                    }
                    //@ts-ignore
                    setCurrentContent(newCurrentContent)
                    setUpdateStatus(EditStatus.SUCCESS)
                    dismissToast()
                    ToastSuccess('🔥 Contenuto aggiornato')
                    props.history.push('/contents/')
                })
            }
        } catch (error) {
            console.log('ERRORE DI AGGIORNAMENTO', error)
            dismissToast()
            setUpdateStatus(EditStatus.FAILED)
        }
    }

    const handleCreateCategory = async (name: string) => {
        const category = { category: name }
        await CustomAxios.post(createCategory(), category).then(async () => {
            dispatch(setCategories())
        })
    }

    const handleCreateTag = async (name: string) => {
        const tag = { tag: name }
        await CustomAxios.post(createTag(), tag).then(async () => {
            dispatch(setTags())
        })
    }

    const handleCreateGuest = async (name: string) => {
        const guest = { complete_name: name }
        await CustomAxios.post(createGuest(), guest).then(async () => {
            dispatch(setGuests())
        })
    }

    const renderButton = () => {
        switch (updateStatus) {
            case EditStatus.FAILED:
                return (
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            handleEdit()
                        }}
                    >
                        Aggiornamento fallito. Riprova
                    </span>
                )
            default:
                return (
                    <a
                        className="contentsButton"
                        style={{ opacity: changed ? 1 : 0.5, cursor: changed ? 'pointer' : 'default' }}
                        onClick={() => {
                            handleEdit()
                        }}
                    >
                        {isNew ? 'Pubblica' : 'Aggiorna'}
                    </a>
                )
        }
    }

    const renderArchiveModal = () => {
        switch (typeArchive) {
            case ContentType.audio:
                return (
                    <ArchiveModal
                        data={audioArchive.filter((content) => content.id !== currentContent.id)}
                        isVisible={visibleModalArchive}
                        type={ContentType.audio}
                        setContent={(url: string) => {
                            setAudioUrl(url)
                            setVisibleModalArchive(false)
                            setTypeArchive(undefined)
                            setChanged(true)
                        }}
                        closeModal={() => setVisibleModalArchive(false)}
                    />
                )
            case ContentType.video:
                return (
                    <ArchiveModal
                        data={videoArchive.filter((content) => content.id !== currentContent.id)}
                        isVisible={visibleModalArchive}
                        type={ContentType.video}
                        setContent={(url: string) => {
                            setVideoUrl(url)
                            setVisibleModalArchive(false)
                            setTypeArchive(undefined)
                            setChanged(true)
                        }}
                        closeModal={() => setVisibleModalArchive(false)}
                    />
                )
            case ContentType.text:
                return (
                    <ArchiveModal
                        data={txtArchive.filter((content) => content.id !== currentContent.id)}
                        isVisible={visibleModalArchive}
                        type={ContentType.text}
                        setContent={(url: string) => {
                            setTxtUrl(url)
                            setVisibleModalArchive(false)
                            setTypeArchive(undefined)
                            setChanged(true)
                        }}
                        closeModal={() => setVisibleModalArchive(false)}
                    />
                )
            case ContentType.image:
                return (
                    <ArchiveModal
                        data={imgArchive.filter((content) => content.id !== currentContent.id)}
                        isVisible={visibleModalArchive}
                        type={ContentType.image}
                        setContent={(url: string) => {
                            setImgUrl(url)
                            setVisibleModalArchive(false)
                            setTypeArchive(undefined)
                            setChanged(true)
                        }}
                        closeModal={() => setVisibleModalArchive(false)}
                    />
                )
            default:
                return null
        }
    }

    return (
        <div className="contentsDetailPage">
            <div className="contentsTitleSection">
                <h2>Il tuo contenuto</h2>
                {isNew ? <p>Crea il tuo nuovo contenuto</p> : <p>{`Aggiornamento di ${currentContent.title}`}</p>}
            </div>
            {renderButton()}
            <div style={{ clear: 'both' }}></div>
            <div className="contentsDetailSection">
                <div className="formGroup">
                    <label>Titolo</label>
                    <input
                        placeholder="Inserisci il titolo"
                        type="text"
                        value={title}
                        onChange={(event) => {
                            setTitle(event.target.value)
                            setChanged(true)
                        }}
                    />
                </div>
                <div className="formGroup">
                    <label>Descrizione</label>
                    <textarea
                        placeholder="Inserisci la descrizione del contenuto"
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value)
                            setChanged(true)
                        }}
                    />
                </div>
                <div className="formGroup col-4">
                    <label>Seleziona le categorie di appartenenza</label>
                    <Select
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0
                            })
                        }}
                        placeholder="Seleziona categoria/e"
                        defaultValue={categoriesForSelect(categoriesLocal)}
                        options={categoriesForSelect(data.categories)}
                        className="select"
                        openMenuOnClick={true}
                        openMenuOnFocus={true}
                        isMulti={true}
                        onChange={(event: any) => {
                            if (event == undefined) {
                                setCategoriesLocal([])
                                return
                            }
                            const newArray = event.map((category: any) => {
                                return { id: category.id, value: category.label }
                            })
                            setCategoriesLocal(newArray)
                            setChanged(true)
                        }}
                        onKeyDown={(event: any) => {
                            if (event.key == 'Enter') {
                                event.preventDefault()
                                const findValue = data.categories.find((category) => category.value.toLowerCase() == event.target.value.toLowerCase())
                                if (findValue == undefined) {
                                    handleCreateCategory(event.target.value)
                                }
                            }
                        }}
                    />
                </div>
                <div className="formGroup col-4">
                    <label>Seleziona i tag riferiti al tuo contenuto</label>
                    <Select
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0
                            })
                        }}
                        placeholder="Seleziona tag"
                        defaultValue={tagsForSelect(tagsLocal)}
                        options={categoriesForSelect(data.tags)}
                        className="select"
                        isMulti
                        onChange={(event: any) => {
                            if (event == undefined) {
                                setTagsLocal([])
                                return
                            }
                            const newArray = event.map((tag: any) => {
                                return { id: tag.id, value: tag.label }
                            })
                            setTagsLocal(newArray)
                            setChanged(true)
                        }}
                        onKeyDown={(event: any) => {
                            if (event.key == 'Enter') {
                                event.preventDefault()
                                const findValue = data.tags.find((tag) => tag.value.toLowerCase() == event.target.value.toLowerCase())
                                if (findValue == undefined) {
                                    handleCreateTag(event.target.value)
                                }
                            }
                        }}
                    />
                </div>
                <div className="formGroup col-4">
                    <label>{"Chi possiamo trovare all'interno del contenuto?"}</label>
                    <Select
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                paddingTop: 0,
                                paddingBottom: 0
                            })
                        }}
                        placeholder="Seleziona ospiti"
                        defaultValue={guestForSelect(guestsLocal)}
                        options={guestForSelect(data.guests)}
                        className="select"
                        isMulti
                        onChange={(event: any) => {
                            if (event == undefined) {
                                setGuestsLocal([])
                                return
                            }
                            const newArray = event.map((guest: any) => {
                                const guestObject: any = data.guests.find((item) => item.id == guest.id)
                                return {
                                    id: guestObject.id,
                                    firstName: guestObject.first_name,
                                    lastName: guestObject.last_name,
                                    completeName: guestObject.completeName
                                }
                            })
                            console.log(newArray)
                            setGuestsLocal(newArray)
                            setChanged(true)
                        }}
                        onKeyDown={(event: any) => {
                            if (event.key == 'Enter') {
                                event.preventDefault()
                                const findValue = data.guests.find((guest) => guest.completeName.toLowerCase() == event.target.value.toLowerCase())
                                if (findValue == undefined) {
                                    handleCreateGuest(event.target.value)
                                }
                            }
                        }}
                    />
                </div>
                <div style={{ clear: 'both' }}></div>
                <div className="formGroup">
                    <label>Testo del contenuto</label>
                    <textarea
                        placeholder="Inserisci il testo del contenuto"
                        value={txt}
                        onChange={(event) => {
                            setTxt(event.target.value)
                            setChanged(true)
                        }}
                    />
                </div>
            </div>
            <div className="cardContainer">
                <div className="cardContent col-3">
                    <div className="cardHeader">
                        <img
                            src={audioCover}
                            className={audioUrl == null || audioUrl == '' ? 'greyScale' : ''}
                            style={{ opacity: audioUrl == null || audioUrl == '' ? 0.4 : 1 }}
                        />
                        <p className="cardTitle">Audio</p>
                        {audioUrl !== null && audioUrl !== '' ? (
                            <p
                                className="deleteContent"
                                onClick={() => {
                                    setChanged(true)
                                    setAudioUrl('')
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} color="#fff" />
                            </p>
                        ) : null}
                        {
                            //@ts-ignore
                            audioUrl !== null && audioUrl !== '' && currentContent.id !== undefined && audioUrl.name == undefined ? (
                                <p
                                    className="previewContent"
                                    onClick={() =>
                                        window.open('https://console.peperoni.ai/single-player?userId=' + user.id + '&idContent=' + currentContent.id, '_blank')
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlay} color="#fff" />
                                </p>
                            ) : null
                        }
                    </div>
                    <div className="formGroup">
                        <label>{audioUrl == null || audioUrl == '' ? 'Carica il contenuto audio' : 'Aggiorna il contenuto audio'}</label>
                        <div className="uploadFileButtons">
                            <span className="custom-file-upload audio">
                                <input type="file" onChange={(event) => handleFileAudioChange(event)} accept="audio/*" />
                                Da file
                            </span>
                            <span
                                className="custom-file-upload audioReverse"
                                onClick={() => {
                                    setVisibleModalArchive(true)
                                    setTypeArchive(ContentType.audio)
                                }}
                            >
                                Da archivio
                            </span>
                        </div>
                    </div>
                </div>
                <div className="cardContent col-3">
                    <div className="cardHeader">
                        <img
                            src={videoCover}
                            className={videoUrl == null || videoUrl == '' ? 'greyScale' : ''}
                            style={{ opacity: videoUrl == null || videoUrl == '' ? 0.4 : 1 }}
                        />
                        <p className="cardTitle">Video</p>
                        {videoUrl !== null && videoUrl !== '' ? (
                            <p
                                className="deleteContent"
                                onClick={() => {
                                    setChanged(true)
                                    setVideoUrl('')
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} color="#fff" />
                            </p>
                        ) : null}
                        {
                            //@ts-ignore
                            videoUrl !== null && videoUrl !== '' && currentContent.id !== undefined && videoUrl.name == undefined ? (
                                <p
                                    className="previewContent"
                                    onClick={() =>
                                        window.open('https://console.peperoni.ai/single-player?userId=' + user.id + '&idContent=' + currentContent.id, '_blank')
                                    }
                                >
                                    <FontAwesomeIcon icon={faPlay} color="#fff" />
                                </p>
                            ) : null
                        }
                    </div>
                    <div className="formGroup">
                        <label>{videoUrl == null || videoUrl == '' ? 'Carica il contenuto video' : 'Aggiorna il contenuto video'}</label>
                        <div className="uploadFileButtons">
                            <span className="custom-file-upload video">
                                <input type="file" onChange={(event) => handleFileVideoChange(event)} accept="video/*" />
                                Da file
                            </span>
                            <span
                                className="custom-file-upload videoReverse"
                                onClick={() => {
                                    setVisibleModalArchive(true)
                                    setTypeArchive(ContentType.video)
                                }}
                            >
                                Da archivio
                            </span>
                        </div>
                    </div>
                </div>
                <div className="cardContent col-3">
                    <div className="cardHeader">
                        <img
                            src={textCover}
                            className={txtUrl == null || txtUrl == '' ? 'greyScale' : ''}
                            style={{ opacity: txtUrl == null || txtUrl == '' ? 0.4 : 1 }}
                        />
                        <p className="cardTitle">Testo</p>
                        {txtUrl !== null && txtUrl !== '' ? (
                            <p
                                className="deleteContent"
                                onClick={() => {
                                    setChanged(true)
                                    setTxtUrl('')
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} color="#fff" />
                            </p>
                        ) : null}
                        {
                            //@ts-ignore
                            txtUrl !== null && txtUrl !== '' && currentContent.id !== undefined && txtUrl.name == undefined ? (
                                <p className="previewContent" onClick={() => window.open(txtUrl, '_blank')}>
                                    <FontAwesomeIcon icon={faEye} color="#fff" />
                                </p>
                            ) : null
                        }
                    </div>
                    <div className="formGroup">
                        <label style={{ marginTop: 2 }}>
                            {txtUrl == null || txtUrl == '' ? 'Carica il contenuto testuale' : 'Aggiorna il contenuto testuale'}
                        </label>
                        <div className="uploadFileButtons">
                            <span className="custom-file-upload text">
                                <input type="file" onChange={(event) => handleFileTextChange(event)} accept="text/plain, .pdf" />
                                Da file
                            </span>
                            <span
                                className="custom-file-upload textReverse"
                                onClick={() => {
                                    setVisibleModalArchive(true)
                                    setTypeArchive(ContentType.text)
                                }}
                            >
                                Da archivio
                            </span>
                        </div>
                    </div>
                </div>
                <div className="cardContent col-3">
                    <div className="cardHeader">
                        <img
                            src={imageCover}
                            className={imgUrl == null || imgUrl == '' ? 'greyScale' : ''}
                            style={{ opacity: imgUrl == null || imgUrl == '' ? 0.4 : 1 }}
                        />
                        <p className="cardTitle">Immagine</p>
                        {imgUrl !== null && imgUrl !== '' ? (
                            <p
                                className="deleteContent"
                                onClick={() => {
                                    setChanged(true)
                                    setImgUrl('')
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} color="#fff" />
                            </p>
                        ) : null}
                        {
                            //@ts-ignore
                            imgUrl !== null && imgUrl !== '' && currentContent.id !== undefined && imgUrl.name == undefined ? (
                                <p className="previewContent" onClick={() => window.open(imgUrl, '_blank')}>
                                    <FontAwesomeIcon icon={faEye} color="#fff" />
                                </p>
                            ) : null
                        }
                    </div>
                    <div className="formGroup">
                        <label>{imgUrl == null || imgUrl == '' ? "Carica l'immagine" : "Aggiorna l'immagine"}</label>
                        <div className="uploadFileButtons">
                            <span className="custom-file-upload img">
                                <input type="file" onChange={(event) => handleFileImageChange(event)} accept="image/*" />
                                Da file
                            </span>
                            <span
                                className="custom-file-upload imgReverse"
                                onClick={() => {
                                    setVisibleModalArchive(true)
                                    setTypeArchive(ContentType.image)
                                }}
                            >
                                Da archivio
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ clear: 'both' }}></div>
            {renderArchiveModal()}
        </div>
    )
}

export default ContentsDetailPage
