import React from 'react'
import moment from 'moment'
import { RssFeed } from '@material-ui/icons'
import addSpreaker from '../../resources/images/icons/spreaker_ok.png'
import { Tooltip } from '@material-ui/core'

export const columns = [
    {
        title: '',
        field: 'file_media',
        cellStyle: { maxWidth: 100 },
        headerStyle: { maxWidth: 100 },
        // eslint-disable-next-line react/display-name
        render: (rowData: any): React.ReactElement => {
            return (
                <p style={{ textAlign: 'center' }}>
                    {rowData.spreakerEpisodeId !== null ? (
                        <Tooltip title="Aggiunto su spreaker">
                            <img height="18px" width="18px" style={{ marginRight: 15 }} src={addSpreaker} />
                        </Tooltip>
                    ) : null}
                    {rowData.importedFrom !== null ? (
                        <Tooltip title={`Sincronizzato dal feed ${rowData.importedFrom}`}>
                            <RssFeed />
                        </Tooltip>
                    ) : null}
                </p>
            )
        }
    },
    {
        title: 'Titolo',
        field: 'title'
    },
    {
        title: 'Descrizione',
        field: 'description',
        // eslint-disable-next-line react/display-name
        render: (rowData: any): React.ReactElement => {
            return <p>{rowData.description !== null && rowData.description.length > 80 ? rowData.description.substr(0, 80) + '...' : rowData.description}</p>
        }
    },
    {
        title: 'Categorie',
        field: 'categories'
    },
    {
        title: 'Tags',
        field: 'tags'
    },
    {
        title: 'Ospiti & Protagonisi',
        field: 'guests'
    },
    {
        title: 'Data di pubblicazione',
        field: 'createdAt',
        // eslint-disable-next-line react/display-name
        render: (rowData: any) => <p>{moment(rowData.createdAt).format('LLL')}</p>
    }
]
