export const version = '0.2'
export const defaultProfileImage = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/user_icon.png'

const baseUrlCore = 'https://api.peperoni.ai/core/pep-core/'
const baseUrlContents = 'https://api.peperoni.ai/contents/pep-contents/'
const baseUrlWebinar = 'https://api.peperoni.ai/webinar/gotowebinar/'

import AWS from 'aws-sdk'
const AWS_config_keys = {
    accessKeyId: 'AKIAVLEKBRWH37HB3VW3',
    secretAccessKey: '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',
    region: 'eu-west-2'
}
export const BUCKET_NAME = '22hbgtest/Peperoni/contents'
export const s3 = new AWS.S3(AWS_config_keys)

//API DI LETTURA
export const getUser = (id: string): string => {
    return baseUrlCore + 'user/' + id
}

export const getSubscriptions = (id: string): string => {
    return baseUrlCore + id + '/subscriptions'
}

export const getContents = (id: string): string => {
    return baseUrlContents + id + '/contents'
}

export const getViewDBWebinar = (id: string): string => {
    return baseUrlWebinar + 'viewDBWebinarUser/UserPeP?myIdPep=' + id
}

export const getAllCatalogs = (id: string): string => {
    return baseUrlWebinar + 'viewDBWebinarInCatalogsDetails/UserPeP?myIdPep=' + id
}

export const getWebinarInCatalogs = (idUser: string, idWebinar: number): string => {
    return baseUrlWebinar + 'viewUserWebinarExistInCatalog/viewWebinar?myIdPep=' + idUser + '&IdWebinar=' + idWebinar
}

export const getExternalFeeds = (idUser: string): string => {
    return `${baseUrlContents}${idUser}/external_feeds`
}

export const syncFeeds = (): string => {
    return `${baseUrlContents}/sys/sync_feeds`
}

export const syncFeed = (idFeed: number): string => {
    return `${baseUrlContents}/sys/sync_feeds/${idFeed}`
}

export const getTags = baseUrlContents + 'tags'
export const getCategories = baseUrlContents + 'categories'
export const getGuests = baseUrlContents + 'guests'

export const getSpreakerUserShow = (idUser: string): string => {
    return baseUrlContents + idUser + '/spreaker/shows'
}

export const refreshSpreakerToken = (idUser: string): string => {
    return baseUrlContents + idUser + '/spreaker/refreshToken'
}

export const getUrlSyncWebinar = (idUser: string): string => {
    return baseUrlWebinar + 'authorizeUser/UserPeP?myIdPep=' + idUser + '&IdAccount=1'
}

export const getServices = (): string => {
    return baseUrlCore + 'services'
}

//API DI SCRITTURA
export const createUser = (): string => {
    return baseUrlCore + 'users'
}

export const editUser = (uid: string): string => {
    return baseUrlCore + 'users/' + uid
}

export const createSubscription = (uid: string): string => {
    return baseUrlCore + uid + '/subscription'
}

export const deleteContent = (uid: string, c_id: number): string => {
    return baseUrlContents + uid + '/content/' + c_id
}

export const createContent = (uid: string): string => {
    return baseUrlContents + uid + '/contents'
}

export const createCategory = (): string => {
    return baseUrlContents + '/categories'
}

export const createTag = (): string => {
    return baseUrlContents + '/tags'
}

export const createGuest = (): string => {
    return baseUrlContents + '/guests'
}

export const updateContent = (uid: string, cid: number): string => {
    return baseUrlContents + uid + '/content/' + cid
}

export const importToSpreaker = (uid: string, showId: number, contentId: number): string => {
    return baseUrlContents + uid + '/spreaker/import/' + showId + '/' + contentId
}

export const removeFromSpreaker = (uid: string, contentSpreakerId: number): string => {
    return baseUrlContents + uid + '/spreaker/delete/' + contentSpreakerId
}

export const setTrashWebinarState = (uid: string, idwebinar: number): string => {
    return baseUrlWebinar + 'SetCestinaStatoWebinar/UserPeP?myIdPep=' + uid + '&IdWebinar=' + idwebinar
}

export const setVisibleWebinarState = (uid: string, idwebinar: number): string => {
    return baseUrlWebinar + 'SetCambiaStatoWebinar/UserPeP?myIdPep=' + uid + '&IdWebinar=' + idwebinar
}

export const addWebinarToCatalog = (uid: string, idwebinar: number, idcatalog: number): string => {
    return baseUrlWebinar + 'AddWebinar2CatalogByUser/UserPeP?myIdPep=' + uid + '&IdWebinar=' + idwebinar + '&IdCatalog=' + idcatalog
}

export const removeWebinarFromCatalog = (uid: string, idwebinar: number, idcatalog: number): string => {
    return baseUrlWebinar + 'RemoveWebinar2CatalogByUser/UserPeP?myIdPep=' + uid + '&IdWebinar=' + idwebinar + '&IdCatalog=' + idcatalog
}

export const createCatalog = (uid: string, name: string): string => {
    return baseUrlWebinar + 'AddCatalogByUser/UserPeP?myIdPep=' + uid + '&Titolo=' + name
}

export const deleteCatalog = (uid: string, idcatalog: number): string => {
    return baseUrlWebinar + 'RemoveCatalogByUser/UserPeP?myIdPep=' + uid + '&IdCatalog=' + idcatalog
}

export const createExternalFeed = (idUser: string): string => {
    return `${baseUrlContents}${idUser}/external_feed`
}

export const editExternalFeed = (idUser: string, feedId: number): string => {
    return `${baseUrlContents}${idUser}/external_feed/${feedId}`
}

export const deleteExternalFeed = (idUser: string, feedId: number): string => {
    return `${baseUrlContents}${idUser}/external_feed/${feedId}`
}
