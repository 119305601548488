import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import user from './user'
import data from './data'
import player from './player'

const config = {
    key: 'root',
    storage: storage,
    whitelist: ['user', 'player'],
    blacklist: ['data']
}

// Group reducers
const rootReducer = combineReducers({
    user,
    data,
    player
})

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(config, rootReducer)

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore)
const store = createStoreWithMiddleware(persistedReducer)
const persistor = persistStore(store)

export { persistor, store }
