import { DATA_ACTIONS } from '../actions/data'
import { ReducerData, ReduxActionData } from './../../types/redurcers'

const defaultValue: ReducerData = {
    contents: [],
    webinar: [],
    tags: [],
    categories: [],
    guests: [],
    catalogs: [],
    spreakerShows: [],
    services: [],
    feeds: []
}

const dataState: (state: ReducerData, action: ReduxActionData<any>) => ReducerData = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case DATA_ACTIONS.SET_CONTENTS:
            return {
                ...state,
                contents: action.payload
            }
        case DATA_ACTIONS.SET_WEBINARS:
            return {
                ...state,
                webinar: action.payload
            }
        case DATA_ACTIONS.SET_FEEDS:
            return {
                ...state,
                feeds: action.payload
            }
        case DATA_ACTIONS.SET_TAGS:
            return {
                ...state,
                tags: action.payload
            }
        case DATA_ACTIONS.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case DATA_ACTIONS.SET_GUESTS:
            return {
                ...state,
                guests: action.payload
            }
        case DATA_ACTIONS.SET_CATALOGS:
            return {
                ...state,
                catalogs: action.payload
            }
        case DATA_ACTIONS.SET_SPREAKER_SHOW:
            return {
                ...state,
                spreakerShows: action.payload
            }
        case DATA_ACTIONS.SET_SERVICES:
            return {
                ...state,
                services: action.payload
            }
        case DATA_ACTIONS.RESET_DATA:
            return defaultValue
        default:
            return state
    }
}

export default dataState
