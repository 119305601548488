import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import { ReducerData, Reducers, ReducerUser } from '../../types/redurcers'
import { useDispatch, useSelector } from 'react-redux'
import { Contents } from '../../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPen } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { PlayerContent } from '../../types/player'
import { setContentPlayer } from '../../store/actions/player'
import { useHistory } from 'react-router-dom'
require('moment/min/locales')
moment.locale('it')

const TimelineView: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const dispatch = useDispatch()
    const history = useHistory()

    const handlePlayer = (item: Contents) => {
        if (item.audioUrl == null && item.videoUrl == null) {
            return
        }
        const contentPlayer: PlayerContent = {
            id: item.id,
            title: item.title,
            date: item.createdAt,
            description: item.description,
            author: user.firstName + ' ' + user.lastName,
            audio_url: item.audioUrl,
            video_url: item.videoUrl,
            img_url: item.imgUrl !== null ? item.imgUrl : user.avatar
        }
        dispatch(setContentPlayer(contentPlayer))
        window.open('https://console.peperoni.ai/single-player?userId=' + user.id + '&idContent=' + item.id, '_blank')
    }

    const handleEdit = (id: number) => {
        const content = data.contents.find((item) => item.id == id)
        history.push('/contents/detail', { content: content, isNew: false })
    }

    const renderItem = (item: Contents) => {
        return (
            <TimelineItem key={item.id} className="timelineItem">
                <TimelineSeparator className="timelineSeparator">
                    <TimelineDot
                        className="timelineDot"
                        style={{
                            opacity: item.audioUrl == null && item.videoUrl == null ? 0.7 : 1,
                            cursor: item.audioUrl == null && item.videoUrl == null ? 'inherit' : 'pointer'
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faPlay}
                            className="iconTimeline"
                            onClick={() => {
                                handlePlayer(item)
                            }}
                        />
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent className="timelineContent">
                    <div className="timelineContentContainer">
                        <h3 className="timelineTitle">{item.title}</h3>
                        <p className="timelineDate">{moment(item.createdAt).format('LLLL')}</p>
                        <p className="timelineDescription">{item.description.length > 500 ? item.description.substr(0, 500) + '...' : item.description}</p>
                        <div className="editTimeline" onClick={() => handleEdit(item.id)}>
                            <FontAwesomeIcon icon={faPen} className="iconTimeline" />
                        </div>
                    </div>
                </TimelineContent>
            </TimelineItem>
        )
    }

    return (
        <div className="timelineView">
            <h2>I miei contenuti</h2>
            <Timeline align="alternate">{data.contents.map((item) => renderItem(item))}</Timeline>
        </div>
    )
}

export default TimelineView
