import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomAxios from '../customComponents/customAxios'
import { createSubscription, getServices } from '../resources/constants'
import { setUserAndFetch } from '../store/actions/thunk_actions'
import { Service } from '../types/data'
import { Reducers, ReducerUser } from '../types/redurcers'
import Services from '../utility/Services'

interface Props {
    service: Services
}

const ServiceActivation: React.FC<Props> = (props) => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [service, setService] = useState<Service | undefined>()
    const dispatch = useDispatch()

    const fetchServices = async () => {
        const services = await CustomAxios.get(getServices()).then((response) => response.data.services)
        const serviceFound = services.find((service: Service) => service.id == props.service)
        setService(serviceFound)
    }

    useEffect(() => {
        fetchServices()
    }, [])

    const handleSubscription = async () => {
        const body = {
            id_service: props.service,
            valid_until: '2050-01-01',
            trial: false
        }
        await CustomAxios.post(createSubscription(user.id), body).then(() => {
            dispatch(setUserAndFetch(user))
        })
    }

    return (
        <div className="containerActivation">
            {service?.isFree ? <h1 className="titleActivation">Servizio non attivo!</h1> : <h1 className="titleActivation">Scopri ora {service?.title}!</h1>}
            {service?.isFree ? (
                <p className="textActivation">{user.firstName}, attiva subito questo servizio.</p>
            ) : (
                <p className="textActivation">
                    {user.firstName}, scopri il servizio {service?.title}.<br />
                    Richiedi ora maggiori informazioni.
                </p>
            )}
            {service?.isFree ? (
                <p className="buttonActivation gradientBackground" onClick={() => handleSubscription()}>
                    Attiva ora
                </p>
            ) : (
                <p
                    className="buttonActivation gradientBackground"
                    onClick={() => window.open('mailto:info@22hbg.com?subject=Richiesta attivazione servizio ' + service?.title)}
                >
                    Contattaci
                </p>
            )}
        </div>
    )
}

export default ServiceActivation
