import firebase from '../customComponents/firebase'
import React, { useState } from 'react'
import CustomAxios from '../customComponents/customAxios'
import { createUser } from '../resources/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import RegistrationStatus from '../utility/RegistrationStatus'

const RegisterPage: React.FC = () => {
    const [requestState, setRequestState] = useState('')
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [agree, setAgree] = useState(false)

    const registerHandle = async () => {
        setRequestState(RegistrationStatus.PENDING)
        try {
            if (agree) {
                await firebase
                    .auth()
                    .createUserWithEmailAndPassword(email, password)
                    .then(async (res) => {
                        const user = await firebase.auth().currentUser
                        user?.sendEmailVerification().then(async () => {
                            const userToCreate = {
                                id_user_pep: res.user?.uid,
                                first_name: name,
                                last_name: surname,
                                email: email,
                                phone: '',
                                avatar: ''
                            }
                            await CustomAxios.post(createUser(), userToCreate).then(() => {
                                setRequestState(RegistrationStatus.SUCCESS)
                            })
                        })
                    })
            }
        } catch (e) {
            console.log('ERRORE', e)
            if (e.code == 'auth/weak-password') {
                setRequestState(RegistrationStatus.PASSWORD_WEAK)
                return
            }
            if (e.code == 'auth/email-already-in-use') {
                setRequestState(RegistrationStatus.EMAIL_IN_USE)
                return
            }
            setRequestState(RegistrationStatus.FAILED)
            return
        }
    }

    const renderButton = () => {
        switch (requestState) {
            case RegistrationStatus.SUCCESS:
                return (
                    <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>
                        Registrazione completata!
                        <br />
                        Controlla la tua casella di posta e conferma la tua registrazione per poter accedere.
                    </p>
                )
            case RegistrationStatus.PENDING:
                return (
                    <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>
                        Ti sto registrando.
                        <br />
                        Attendi qualche secondo...
                    </p>
                )
            case RegistrationStatus.FAILED:
                return (
                    <>
                        <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>
                            Registrazione fallita.
                            <br />
                            Sei sicuro che questa email non sia già registrata?
                        </p>
                        <p
                            onClick={() => {
                                setRequestState('')
                            }}
                            style={{ textDecoration: 'underline', color: '#fff', textAlign: 'center', cursor: 'pointer' }}
                        >
                            Riprova
                        </p>
                    </>
                )
            case RegistrationStatus.PASSWORD_WEAK:
                return (
                    <>
                        <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>
                            Registrazione fallita.
                            <br />
                            La password deve essere almeno 6 caratteri.
                        </p>
                        <p
                            onClick={() => {
                                setRequestState('')
                            }}
                            style={{ textDecoration: 'underline', color: '#fff', textAlign: 'center', cursor: 'pointer' }}
                        >
                            Riprova
                        </p>
                    </>
                )
            case RegistrationStatus.EMAIL_IN_USE:
                return (
                    <>
                        <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>
                            Registrazione fallita.
                            <br />
                            {"L'email che hai scelto è già stata utilizzata da un altro account."}
                        </p>
                        <p
                            onClick={() => {
                                setRequestState('')
                            }}
                            style={{ textDecoration: 'underline', color: '#fff', textAlign: 'center', cursor: 'pointer' }}
                        >
                            Riprova
                        </p>
                    </>
                )
            default:
                return (
                    <button type="submit" className="submitLogin">
                        Registra
                    </button>
                )
        }
    }

    return (
        <div>
            <div className="loginContainer gradientBackground">
                <form
                    className="form"
                    onSubmit={(event) => {
                        event.preventDefault()
                        registerHandle()
                    }}
                >
                    <h2>Registrati</h2>
                    <div className="form-group">
                        <input required type="text" onChange={(event) => setName(event.target.value)} placeholder="Nome" />
                    </div>
                    <div className="form-group">
                        <input required type="text" onChange={(event) => setSurname(event.target.value)} placeholder="Cognome" />
                    </div>
                    <div className="form-group">
                        <input required type="email" onChange={(event) => setEmail(event.target.value)} placeholder="Email" />
                    </div>
                    <div className="form-group">
                        <input required type="password" onChange={(event) => setPassword(event.target.value)} placeholder="Password" />
                    </div>
                    <div className="check-form">
                        <input required type="checkbox" onChange={() => setAgree(!agree)} className="checkAccept" />
                        <span className="labelAccept">Accetto termini e condizioni</span>
                    </div>
                    <div className="form-group">{renderButton()}</div>
                </form>
                <div className="footerLogin">
                    <div style={{ textAlign: 'center', margin: 10 }}>
                        <NavLink
                            to="/"
                            //@ts-ignore
                            exact
                            className="loginLink"
                            activeClassName="active"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} color="#fff" /> Indietro
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterPage
