import React from 'react'
import { Contents } from '../../types/data'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import MaterialTable from 'material-table'
import { ContentType } from '../../utility/ContentType'
import { locale_itIT } from '../../utility/tableLocale'
import { Reducers, ReducerUser } from '../../types/redurcers'
import { useSelector } from 'react-redux'

interface Props {
    isVisible: boolean
    data: Contents[]
    type: ContentType
    setContent: (url: string) => void
    closeModal: () => void
}

const ArchiveModal: React.FC<Props> = (props) => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const columns = [
        {
            title: 'Nome file',
            field: 'title'
        }
    ]

    const renderData = () => {
        switch (props.type) {
            case ContentType.audio: {
                const newArchive: Contents[] = []
                props.data.forEach((content) => {
                    const index = newArchive.findIndex((c) => c.audioUrl == content.audioUrl)
                    if (index == -1) {
                        newArchive.push(content)
                    }
                })
                return newArchive.map((content) => {
                    return {
                        id: content.id,
                        title: content.audioUrl.split('/').pop(),
                        url: content.audioUrl
                    }
                })
            }
            case ContentType.video: {
                const newArchive: Contents[] = []
                props.data.forEach((content) => {
                    const index = newArchive.findIndex((c) => c.videoUrl == content.videoUrl)
                    if (index == -1) {
                        newArchive.push(content)
                    }
                })
                return newArchive.map((content) => {
                    return {
                        id: content.id,
                        title: content.videoUrl.split('/').pop(),
                        url: content.videoUrl
                    }
                })
            }
            case ContentType.text: {
                const newArchive: Contents[] = []
                props.data.forEach((content) => {
                    const index = newArchive.findIndex((c) => c.txtUrl == content.txtUrl)
                    if (index == -1) {
                        newArchive.push(content)
                    }
                })
                return newArchive.map((content) => {
                    return {
                        id: content.id,
                        title: content.txtUrl.split('/').pop(),
                        url: content.txtUrl
                    }
                })
            }
            case ContentType.image: {
                const newArchive: Contents[] = []
                props.data.forEach((content) => {
                    const index = newArchive.findIndex((c) => c.imgUrl == content.imgUrl)
                    if (index == -1) {
                        newArchive.push(content)
                    }
                })
                return newArchive.map((content) => {
                    return {
                        id: content.id,
                        title: content.imgUrl.split('/').pop(),
                        url: content.imgUrl
                    }
                })
            }
        }
    }

    const renderActions = () => {
        return [
            () => ({
                icon: 'preview',
                iconProps: {
                    color: 'primary'
                },
                tooltip: 'Seleziona',
                onClick: (event: any, rowData: any) => {
                    if (props.type == ContentType.audio || props.type == ContentType.video) {
                        window.open('https://console.peperoni.ai/single-player?userId=' + user.id + '&idContent=' + rowData.id, '_blank')
                    } else {
                        window.open(rowData.url, '_blank')
                    }
                }
            }),
            () => ({
                icon: 'check',
                iconProps: {
                    color: 'primary'
                },
                tooltip: 'Seleziona',
                onClick: (event: any, rowData: any) => props.setContent(rowData.url)
            })
        ]
    }

    return (
        <Dialog fullWidth={true} open={props.isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Archivio</DialogTitle>
            <DialogContent>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderData()}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50]
                    }}
                    //@ts-ignore
                    actions={renderActions()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.closeModal()}>Chiudi</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ArchiveModal
