import firebase from '../customComponents/firebase'
import React, { useState } from 'react'
import CustomAxios from '../customComponents/customAxios'
import LoginStatus from '../utility/LoginStatus'
import { User } from '../types/user'
import { getUser } from '../resources/constants'
import { useDispatch } from 'react-redux'
import { setUserAndFetch } from '../store/actions/thunk_actions'
import { NavLink } from 'react-router-dom'

const LoginPage: React.FC = () => {
    const [requestState, setRequestState] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()

    const loginHandle = async () => {
        setRequestState(LoginStatus.PENDING)
        try {
            const user = await firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((res) => res.user)
            const id = user !== null ? user.uid : ''
            const userFetch = await CustomAxios.get(getUser(id)).then((response) => response.data.results)
            const userLog: User = {
                id: userFetch.idUserPep,
                email: userFetch.email,
                firstName: userFetch.firstName,
                lastName: userFetch.lastName,
                avatar: userFetch.avatar,
                phone: userFetch.phone,
                bio: userFetch.bio
            }
            dispatch(setUserAndFetch(userLog))
            setRequestState(LoginStatus.SUCCESS)
        } catch (e) {
            setRequestState(LoginStatus.FAILED)
            console.log('ERRORE DI CONNESSIONE', e)
        }
    }

    return (
        <div>
            <div className="loginContainer gradientBackground">
                <img src="https://22hbgtest.s3.eu-west-2.amazonaws.com/Peperoni/contents/general/Peperoni-logo-white.svg" />
                <form
                    className="form"
                    onSubmit={(event) => {
                        event.preventDefault()
                        loginHandle()
                    }}
                >
                    <h2>Login</h2>
                    <div className="form-group">
                        <input type="email" onChange={(event) => setEmail(event.target.value)} placeholder="Email" />
                    </div>
                    <div className="form-group">
                        <input type="password" onChange={(event) => setPassword(event.target.value)} placeholder="Password" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="submitLogin">
                            Accedi
                        </button>
                    </div>
                    <p
                        style={{
                            padding: 18,
                            fontSize: 20,
                            color: '#fff',
                            textAlign: 'center',
                            opacity: requestState == LoginStatus.FAILED ? 1 : 0
                        }}
                    >
                        Login fallito, riprova
                    </p>
                </form>
                <div className="footerLogin">
                    <NavLink
                        to="/forgot-password"
                        //@ts-ignore
                        exact
                        className="loginLink"
                        activeClassName="active"
                    >
                        Password dimenticata?
                    </NavLink>
                    <NavLink
                        to="/register"
                        //@ts-ignore
                        exact
                        className="loginLink"
                        activeClassName="active"
                    >
                        Registrati
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default LoginPage
