import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerUser } from '../../types/redurcers'
import './Webinar.css'
import MaterialTable from 'material-table'
import { columns } from './WebinarTableColumns'
import moment from 'moment'
import { locale_itIT } from '../../utility/tableLocale'
import { Webinar } from '../../types/data'
import { setWebinars } from '../../store/actions/thunk_actions'
import Services from '../../utility/Services'
import CustomAxios from '../../customComponents/customAxios'
import { setTrashWebinarState, setVisibleWebinarState, getUrlSyncWebinar } from '../../resources/constants'
import ServiceActivation from '../../components/ServiceActivation'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

require('moment/min/locales')
moment.locale('it')

interface Props {
    history: any
}

const WebinarsTablePage: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<Webinar>()
    const [visibleRefreshModal, setVisibleRefreshModal] = useState(false)
    const dispatch = useDispatch()

    const handleEdit = (id: number) => {
        const webinar = data.webinar.find((item) => item.ID == id)
        props.history.push('/webinars/detail', { webinar: webinar })
    }

    const handleDelete = async (idWebinar: number) => {
        await CustomAxios.get(setTrashWebinarState(user.id, idWebinar)).then(async () => {
            await dispatch(setWebinars(user.id))
        })
        setVisibleDeleteModal(false)
        setItemToDelete(undefined)
    }

    const handleVisibility = async (webinar: Webinar) => {
        await CustomAxios.get(setVisibleWebinarState(user.id, webinar.idwebinar)).then(async () => {
            await dispatch(setWebinars(user.id))
        })
    }

    const handleSync = async () => {
        const linkToOpen = await CustomAxios.get(getUrlSyncWebinar(user.id)).then((res) => {
            return res.data
        })
        window.open(linkToOpen, '_blank')
        setVisibleRefreshModal(false)
    }

    const renderModalDelete = () => {
        return (
            <Dialog open={visibleDeleteModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi cancellare questo webinar?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Stai eliminando {itemToDelete?.subject}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleDeleteModal(false)
                                setItemToDelete(undefined)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={() => {
                                //@ts-ignore
                                handleDelete(itemToDelete?.id)
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const renderModalSync = () => {
        return (
            <Dialog open={visibleRefreshModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi effettuare la sincronizzazione con GoToWebinar?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        La procedura di sincronizzazione avverrà su una finestra separata. Ricorda di effettuare il login su GoToWebinar con le credenziali
                        dell&apos;account MASTER. Al termine della procedura, ritorna su Peperoni.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleRefreshModal(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={() => {
                                handleSync()
                            }}
                            color="secondary"
                        >
                            Sincronizza
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const renderData = useMemo(() => {
        if (data.webinar !== undefined) {
            return data.webinar.map((webinar: Webinar) => {
                const namesRelators =
                    webinar.relatori !== null
                        ? webinar.relatori.map((relatore) => {
                              return relatore.name
                          })
                        : []
                const namesTimes =
                    webinar.times !== null
                        ? webinar.times.map((time) => {
                              return 'Dal ' + moment(time.startTime).format('LLL') + ' al ' + moment(time.endTime).format('LLL')
                          })
                        : []
                return {
                    id: webinar.ID,
                    account: webinar.account,
                    subject: webinar.subject,
                    description:
                        webinar.description !== null && webinar.description.length > 70 ? webinar.description.substr(0, 70) + '...' : webinar.description,
                    relators: namesRelators.join('\n'),
                    times: namesTimes.join('\n'),
                    attivo: webinar.attivo,
                    linkDownload: webinar.linkDownload,
                    idwebinar: webinar.idwebinar
                }
            })
        } else {
            return []
        }
    }, [data.webinar])

    const renderActions = useMemo(() => {
        const actions = [
            (rowData: any) => ({
                icon: 'subject',
                iconProps: {
                    color: rowData.textUrl !== null ? 'primary' : 'disabled'
                },
                tooltip: 'Dettagli webinar',
                onClick: (event: any, rowData: any) => handleEdit(rowData.id)
            }),
            (rowData: any) => ({
                icon: rowData.attivo ? 'visibility_off' : 'visibility',
                tooltip: rowData.attivo ? 'Nascondi webinar' : 'Rendi visibile webinar',
                iconProps: { color: 'primary' },
                onClick: () => handleVisibility(rowData)
            }),
            (rowData: any) => ({
                icon: 'theaters',
                iconProps: {
                    color: rowData.linkDownload !== null ? 'secondary' : 'disabled'
                },
                tooltip: 'Allegato',
                onClick: (event: any, rowData: any) => window.open(rowData.linkDownload),
                disabled: rowData.linkDownload == null
            }),
            {
                icon: 'delete',
                iconProps: { color: 'error' },
                tooltip: 'Elimina',
                onClick: (event: any, rowData: any) => {
                    setVisibleDeleteModal(true)
                    setItemToDelete(rowData)
                }
            }
        ]

        return actions
    }, [])

    if (!user.services.includes(Services.Webinar)) {
        return <ServiceActivation service={Services.Webinar} />
    }

    return (
        <div className="webinarsTablePage">
            <div className="webinarsTitleSection">
                <h2>Elenco dei webinar</h2>
                <p>I tuoi webinar presenti su GoToWebinar</p>
            </div>
            <a
                className="webinarsButton"
                onClick={() => {
                    setVisibleRefreshModal(true)
                }}
            >
                Sincronizza webinar
            </a>
            <a
                className="catalogsButton"
                onClick={() => {
                    props.history.push('/webinars/catalogs')
                }}
            >
                Cataloghi
            </a>
            <div style={{ clear: 'both' }}></div>
            <div className="webinarsTableSection">
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderData}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50]
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            {renderModalDelete()}
            {renderModalSync()}
        </div>
    )
}

export default WebinarsTablePage
