import { ReduxAction } from '../../types/redurcers'
import { User } from '../../types/user'

export enum USER_ACTIONS {
    SET_USER = 'userAction/setUser',
    LOGOUT_USER = 'userAction/logoutUser',
    SET_SERVICES = 'userAction/setServices',
    SET_HAS_SPREAKER = 'userAction/setHasSpreaker'
}

export const setUser: ReduxAction<User> = (user) => {
    return {
        type: USER_ACTIONS.SET_USER,
        payload: user
    }
}

export const logoutUser: ReduxAction<void> = () => {
    return {
        type: USER_ACTIONS.LOGOUT_USER
    }
}

export const setServices: ReduxAction<number[]> = (services) => {
    return {
        type: USER_ACTIONS.SET_SERVICES,
        payload: services
    }
}

export const setHasSpreaker: ReduxAction<boolean> = (status) => {
    return {
        type: USER_ACTIONS.SET_HAS_SPREAKER,
        payload: status
    }
}
