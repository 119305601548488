import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Reducers, ReducerUser } from '../../types/redurcers'
import firebase, { storage } from '../../customComponents/firebase'
import CustomAxios from '../../customComponents/customAxios'
import { editUser } from '../../resources/constants'
import { setUserAndFetch } from '../../store/actions/thunk_actions'
import EditStatus from '../../utility/EditStatus'

const ProfileView: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const imgUser = useRef(null)
    const [firstName, setFirstName] = useState(user.firstName)
    const [lastName, setLastName] = useState(user.lastName)
    const [phone, setPhone] = useState(user.phone)
    const [bio, setBio] = useState(user.bio)
    const [img, setImg] = useState(user.avatar)
    const [password, setPassword] = useState('')
    const [newImage, setNewImage] = useState()
    const [changed, setChanged] = useState(false)
    const [editStatus, setEditStatus] = useState('')
    const dispatch = useDispatch()

    const handleChangeFile = (e: any) => {
        if (e.target.files[0]) {
            const image = e.target.files[0]
            setImg(image)
            setNewImage(image)
            console.log('IMMAGINE', image)
            const fr = new FileReader()
            fr.onload = function () {
                //@ts-ignore
                imgUser.current.src = fr.result
            }
            fr.readAsDataURL(image)
        }
    }

    const handleUpload = () => {
        if (img == user.avatar) {
            return
        }
        //@ts-ignore
        const uploadTask = storage.ref(`images/${newImage.name}`).put(newImage)
        console.log(uploadTask)
        uploadTask.on(
            'state_changed',
            (error) => {
                // Error function ...
                console.log(error)
            },
            () => {
                // complete function ...
                storage
                    .ref('images')
                    //@ts-ignore
                    .child(newImage.name)
                    .getDownloadURL()
                    .then((url) => {
                        console.log(url)
                        setImg(url)
                        setNewImage(undefined)
                        setChanged(true)
                    })
            }
        )
    }

    const handleUpdate = async () => {
        if (!changed || editStatus == EditStatus.PENDING) {
            return
        }
        setEditStatus(EditStatus.PENDING)
        const newUser = {
            id: user.id,
            first_name: firstName,
            last_name: lastName,
            email: user.email,
            phone: phone,
            bio: bio,
            avatar: img,
            password: password
        }
        try {
            const currentUser = firebase.auth().currentUser
            await CustomAxios.put(editUser(user.id), newUser).then(async () => {
                if (newUser.password !== '' && newUser.password.length > 6) {
                    currentUser?.updatePassword(newUser.password)
                }
                const userLog = {
                    id: user.id,
                    email: newUser.email,
                    firstName: newUser.first_name,
                    lastName: newUser.last_name,
                    avatar: newUser.avatar,
                    phone: newUser.phone,
                    bio: newUser.bio
                }
                dispatch(setUserAndFetch(userLog))
                setEditStatus(EditStatus.SUCCESS)
                setChanged(false)
                setPassword('')
            })
        } catch (error) {
            console.log('ERRORE', error)
            setEditStatus(EditStatus.FAILED)
        }
    }

    return (
        <div className="profileView">
            <h2>Informazioni personali</h2>
            <div className="profileFormContainer">
                <div className="colProfileForm">
                    <div className="rowProfileForm">
                        <div className="colInput">
                            <label>Nome</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(event) => {
                                    setFirstName(event.target.value)
                                    setChanged(true)
                                }}
                            />
                        </div>
                        <div className="colInput">
                            <label>Cognome</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(event) => {
                                    setLastName(event.target.value)
                                    setChanged(true)
                                }}
                            />
                        </div>
                    </div>
                    <div className="rowProfileForm">
                        <div className="colInput">
                            <label>Email</label>
                            <input type="email" disabled value={user.email} />
                        </div>
                        <div className="colInput">
                            <label>Password</label>
                            <input
                                type="password"
                                placeholder={'Inserisci nuova password'}
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value)
                                    setChanged(true)
                                }}
                            />
                        </div>
                    </div>
                    <div className="rowProfileForm">
                        <div className="colInput">
                            <label>Telefono</label>
                            <input
                                type="tel"
                                value={phone}
                                onChange={(event) => {
                                    setPhone(event.target.value)
                                    setChanged(true)
                                }}
                            />
                        </div>
                    </div>
                    <div className="rowProfileForm">
                        <div className="colInput" style={{ width: '80%' }}>
                            <label>Bio</label>
                            <textarea
                                placeholder={bio == '' ? 'Inserisci qui la tua descrizione...' : ''}
                                onChange={(event) => {
                                    setBio(event.target.value)
                                    setChanged(true)
                                }}
                                value={bio !== '' ? bio : ''}
                            />
                        </div>
                    </div>
                </div>
                <div className="colProfileImage">
                    <p>{"Clicca sull'immagine per personalizzare il logo"}</p>
                    <div className="imgForm">
                        <input type="file" onChange={(event) => handleChangeFile(event)} accept="image/*" />
                        <img src={img} ref={imgUser} />
                        <button onClick={() => handleUpload()} className="uploadButton" style={{ display: newImage !== undefined ? 'block' : 'none' }}>
                            Carica immagine
                        </button>
                        <button onClick={() => handleUpdate()} className="updateButton" style={{ opacity: changed ? 1 : 0.5 }}>
                            Aggiorna
                        </button>
                        {editStatus == EditStatus.FAILED && (
                            <p style={{ textAlign: 'center' }}>
                                Aggiornamento fallito.
                                <br />
                                Riprova
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileView
