export const locale_itIT = {
    body: {
        emptyDataSourceMessage: 'Nessun contenuto trovato',
        addTooltip: 'Aggiungi',
        deleteTooltip: 'Rimuovi',
        editTooltip: 'Modifica',
        filterRow: {
            filterTooltip: 'Filtra'
        },
        editRow: {
            deleteText: 'Vuoi rimuovere questa riga?',
            cancelTooltip: 'Annulla',
            saveTooltip: 'Salva'
        }
    },
    grouping: {
        groupedBy: 'Raggruppato per:'
    },
    header: {
        actions: 'Azioni'
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} di {count}',
        labelRowsSelect: 'righe',
        labelRowsPerPage: 'righe per pagina:',
        firstAriaLabel: 'Prima pagina',
        firstTooltip: 'Prima pagina',
        previousAriaLabel: 'Pagina precedente',
        previousTooltip: 'Pagina precedente',
        nextAriaLabel: 'Pagina successiva',
        nextTooltip: 'Pagina successiva',
        lastAriaLabel: 'Ultima pagina',
        lastTooltip: 'Ultima pagina'
    },
    toolbar: {
        addRemoveColumns: 'Aggiungi o rimuovi colonne',
        nRowsSelected: '{0} righe selezionate',
        showColumnsTitle: 'Vedi le colonne',
        showColumnsAriaLabel: 'Vedi le colonne',
        exportTitle: 'Esporta',
        exportAriaLabel: 'Esporta',
        exportCSVName: 'Esporta in CSV',
        exportPDFName: 'Esporta in PDF',
        searchTooltip: 'Cerca',
        searchPlaceholder: 'Cerca'
    }
}
