import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerUser } from '../../types/redurcers'
import './ExternalFeeds.css'
import MaterialTable from 'material-table'
import { columns } from './ExternalFeedsTableColumns'
import { locale_itIT } from '../../utility/tableLocale'
import { Feed } from '../../types/data'
import CustomAxios from '../../customComponents/customAxios'
import { deleteExternalFeed, syncFeed, syncFeeds } from '../../resources/constants'
import { setContents, setFeeds } from '../../store/actions/thunk_actions'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faSync } from '@fortawesome/free-solid-svg-icons'
import { ToastSuccess } from '../../utility/Toast'

interface Props {
    history: any
}

const ExternalFeedsTablePage: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
    const [selectedFeed, setSelectedFeed] = useState<Feed | undefined>()
    const [visibleModalActions, setVisibleModalActions] = useState(false)
    const dispatch = useDispatch()

    const newFeed: Feed = {
        id: -1,
        feedUrl: '',
        active: false,
        lastUpdate: ''
    }

    const handleEdit = (id: number) => {
        const feed = data.feeds.find((item) => item.id === id)
        props.history.push('/externalFeeds/detail', { feed, isNew: false })
    }

    const handleDelete = async (feedId: number) => {
        await CustomAxios.delete(deleteExternalFeed(user.id, feedId)).then(async () => await dispatch(setFeeds(user.id)))
        setVisibleDeleteModal(false)
        setVisibleModalActions(false)
        setSelectedFeed(undefined)
    }

    const renderActionsModal = () => {
        return (
            <Dialog fullWidth={true} open={visibleModalActions} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{selectedFeed?.feedUrl}</DialogTitle>
                <DialogContent>
                    <div className="actionsList">
                        {selectedFeed !== undefined && (
                            <div
                                className="action edit"
                                onClick={() => {
                                    handleEdit(selectedFeed.id)
                                }}
                            >
                                <FontAwesomeIcon icon={faPen} style={{ marginRight: 20 }} />
                                Modifica feed
                            </div>
                        )}
                        {selectedFeed !== undefined && (
                            <div
                                className="action copy"
                                onClick={async () => {
                                    await CustomAxios.get(syncFeed(selectedFeed.id))
                                    dispatch(setFeeds(user.id))
                                    ToastSuccess('🚀 Sincronizzazione completata')
                                    setVisibleModalActions(false)
                                }}
                            >
                                <FontAwesomeIcon icon={faSync} style={{ marginRight: 20 }} />
                                Sincronizza feed
                            </div>
                        )}
                        {selectedFeed !== undefined && (
                            <div
                                className="action remove"
                                onClick={() => {
                                    setVisibleDeleteModal(true)
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: 20 }} />
                                Elimina feed
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleModalActions(false)
                                setSelectedFeed(undefined)
                            }}
                            color="secondary"
                        >
                            Chiudi
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const renderData = useMemo(() => {
        if (data.feeds === undefined) {
            return []
        }

        return data.feeds
    }, [data.feeds])

    const renderActions = useMemo(() => {
        const actions = [
            () => ({
                icon: 'more_horiz',
                iconProps: {
                    color: 'primary',
                    fontSize: 'large'
                },
                tooltip: 'Menu',
                onClick: (event: any, rowData: any) => {
                    setVisibleModalActions(true)
                    setSelectedFeed(rowData)
                }
            })
        ]

        return actions
    }, [])

    const renderModalDelete = () => {
        return (
            <Dialog open={visibleDeleteModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi cancellare questo feed?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Stai eliminando {selectedFeed?.feedUrl}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleDeleteModal(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={() => {
                                // @ts-ignore
                                handleDelete(selectedFeed?.id)
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <div className="contentsTablePage">
            <div className="contentsTitleSection">
                <h2>Elenco dei feed esterni</h2>
                <p>Feed collegati da cui verranno sincronizzati i contenuti</p>
            </div>
            <a
                className="contentsButton"
                onClick={() => {
                    props.history.push('/externalFeeds/detail', {
                        feed: newFeed,
                        isNew: true
                    })
                }}
            >
                Aggiungi nuovo
            </a>
            <a
                className="contentsButton"
                onClick={async () => {
                    await CustomAxios.get(syncFeeds())
                    dispatch(setFeeds(user.id))
                    ToastSuccess('🚀 Sincronizzazione completata')
                }}
            >
                Sincronizzazione feed
            </a>
            <div style={{ clear: 'both' }}></div>
            <div className="contentsTableSection">
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderData}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50]
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            {renderModalDelete()}
            {renderActionsModal()}
        </div>
    )
}

export default ExternalFeedsTablePage
