import firebase from '../customComponents/firebase'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import ForgotPasswordStatus from '../utility/ForgotPasswordStatus'

const ForgotPassowrdPage: React.FC = () => {
    const [requestState, setRequestState] = useState('')
    const [email, setEmail] = useState('')

    const handleRequest = async () => {
        setRequestState(ForgotPasswordStatus.PENDING)
        try {
            await firebase
                .auth()
                .sendPasswordResetEmail(email)
                .then(() => {
                    setRequestState(ForgotPasswordStatus.SUCCESS)
                })
        } catch (error) {
            console.log(error)
            if (error.code == 'auth/user-not-found') {
                setRequestState(ForgotPasswordStatus.USER_NOT_FOUND)
                return
            }
            setRequestState(ForgotPasswordStatus.FAILED)
        }
    }

    const renderButton = () => {
        switch (requestState) {
            case ForgotPasswordStatus.SUCCESS:
                return <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>{"Abbiamo inviato un'email alla tua casella di posta."}</p>
            case ForgotPasswordStatus.PENDING:
                return <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>Sto inviando la mail</p>
            case ForgotPasswordStatus.USER_NOT_FOUND:
                return (
                    <>
                        <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>
                            {"L'indirizzo email inserito non è presente nei nostri database."}
                            <br />
                            {"Inserisci l'indirizzo email che hai utilizzato durante la registrazione."}
                        </p>
                        <p
                            onClick={() => {
                                setRequestState('')
                            }}
                            style={{ textDecoration: 'underline', color: '#fff', textAlign: 'center', cursor: 'pointer' }}
                        >
                            Riprova
                        </p>
                    </>
                )
            case ForgotPasswordStatus.FAILED:
                return (
                    <>
                        <p style={{ padding: 18, fontSize: 20, color: '#fff', textAlign: 'center' }}>Richiesta di reset password fallita.</p>
                        <p
                            onClick={() => {
                                setRequestState('')
                            }}
                            style={{ textDecoration: 'underline', color: '#fff', textAlign: 'center', cursor: 'pointer' }}
                        >
                            Riprova
                        </p>
                    </>
                )
            default:
                return (
                    <button type="submit" className="submitLogin">
                        Invia
                    </button>
                )
        }
    }

    return (
        <div>
            <div className="loginContainer gradientBackground">
                <form
                    className="form"
                    onSubmit={(event) => {
                        event.preventDefault()
                        handleRequest()
                    }}
                >
                    <h2>Hai dimenticato la password?</h2>
                    <p style={{ textAlign: 'center', color: '#ccc' }}>Inserisci la tua email di accesso a Peperoni</p>
                    <div className="form-group">
                        <input required type="email" onChange={(event) => setEmail(event.target.value)} placeholder="Email" />
                    </div>
                    <div className="form-group">{renderButton()}</div>
                </form>
                <div className="footerLogin">
                    <div style={{ textAlign: 'center', margin: 10 }}>
                        <NavLink
                            to="/"
                            //@ts-ignore
                            exact
                            className="loginLink"
                            activeClassName="active"
                        >
                            <FontAwesomeIcon icon={faArrowLeft} color="#fff" /> Indietro
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassowrdPage
