import { USER_ACTIONS } from '../actions/user'
import { ReducerUser, ReduxActionData } from './../../types/redurcers'

const defaultValue: ReducerUser = {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    avatar: '',
    phone: '',
    bio: '',
    services: [],
    hasSpreaker: false
}

const userState: (state: ReducerUser, action: ReduxActionData<any>) => ReducerUser = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case USER_ACTIONS.SET_USER:
            return {
                ...state,
                ...action.payload
            }
        case USER_ACTIONS.LOGOUT_USER:
            return defaultValue
        case USER_ACTIONS.SET_SERVICES:
            return {
                ...state,
                services: action.payload
            }
        case USER_ACTIONS.SET_HAS_SPREAKER:
            return {
                ...state,
                hasSpreaker: action.payload
            }
        default:
            return state
    }
}

export default userState
