import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Feed } from '../../types/data'
import { Reducers, ReducerUser } from '../../types/redurcers'
import './ExternalFeeds.css'
import CustomAxios from '../../customComponents/customAxios'
import { createExternalFeed, editExternalFeed } from '../../resources/constants'
import { setFeeds } from '../../store/actions/thunk_actions'
import EditStatus from '../../utility/EditStatus'
import { dismissToast, ToastLoading, ToastSuccess } from '../../utility/Toast'
import { Checkbox } from '@material-ui/core'

interface Props {
    location: any
    history: any
}

const ExternalFeedsDetailPage: React.FC<Props> = (props) => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [updateStatus, setUpdateStatus] = useState('')
    const [currentFeed, setCurrentFeed] = useState<Feed>(props.location.state.feed)
    const [url, setUrl] = useState<string>(currentFeed.feedUrl)
    const [active, setActive] = useState<boolean>(currentFeed.active)
    const [changed, setChanged] = useState(false)
    const [isNew, setIsNew] = useState(props.location.state.isNew)

    const dispatch = useDispatch()

    const handleEdit = async () => {
        if (!changed || updateStatus == EditStatus.PENDING) {
            return
        }
        setUpdateStatus(EditStatus.PENDING)
        ToastLoading('⌛ Sto aggiornando')

        const feedToUpload = {
            feed_url: url,
            active
        }

        try {
            if (currentFeed.id === undefined || currentFeed.id === -1 || isNew) {
                await CustomAxios.post(createExternalFeed(user.id), feedToUpload).then(async () => {
                    await dispatch(setFeeds(user.id))
                    setCurrentFeed({
                        ...currentFeed,
                        feedUrl: url,
                        active
                    })
                    setIsNew(false)
                    setUpdateStatus(EditStatus.SUCCESS)
                    dismissToast()
                    ToastSuccess('🚀 Contenuto pubblicato')
                    props.history.push('/externalFeeds/')
                })
            } else {
                await CustomAxios.put(editExternalFeed(user.id, currentFeed.id), feedToUpload).then(async () => {
                    await dispatch(setFeeds(user.id))
                    setCurrentFeed({
                        ...currentFeed,
                        feedUrl: url,
                        active
                    })
                    setUpdateStatus(EditStatus.SUCCESS)
                    dismissToast()
                    ToastSuccess('🔥 Contenuto aggiornato')
                    props.history.push('/externalFeeds/')
                })
            }
        } catch (error) {
            console.log('ERRORE DI AGGIORNAMENTO', error)
            dismissToast()
            setUpdateStatus(EditStatus.FAILED)
        }
    }

    const renderButton = () => {
        switch (updateStatus) {
            case EditStatus.FAILED:
                return (
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            handleEdit()
                        }}
                    >
                        Aggiornamento fallito. Riprova
                    </span>
                )
            default:
                return (
                    <a
                        className="contentsButton"
                        style={{ opacity: changed ? 1 : 0.5, cursor: changed ? 'pointer' : 'default' }}
                        onClick={() => {
                            handleEdit()
                        }}
                    >
                        {isNew ? 'Pubblica' : 'Aggiorna'}
                    </a>
                )
        }
    }

    return (
        <div className="contentsDetailPage">
            <div className="contentsTitleSection">
                <h2>Il tuo feed</h2>
                {isNew ? <p>Collega un nuovo feed</p> : <p>{`Aggiornamento di ${currentFeed.feedUrl}`}</p>}
            </div>
            {renderButton()}
            <div style={{ clear: 'both' }}></div>
            <div className="contentsDetailSection">
                <div className="formGroup">
                    <label>Feed url</label>
                    <input
                        placeholder="Inserisci l'url del feed"
                        type="text"
                        value={url}
                        onChange={(event) => {
                            setUrl(event.target.value)
                            setChanged(true)
                        }}
                    />
                </div>
                <div className="formGroup">
                    <label>Attivato</label>
                    <Checkbox
                        value={active}
                        onChange={(e) => {
                            setActive(e.target.checked)
                            setChanged(true)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ExternalFeedsDetailPage
