import { Contents, Tags, Categories, Guests, Webinar, Catalogs, SpreakerShow, Service, Feed } from '../../types/data'
import { ReduxAction } from '../../types/redurcers'

export enum DATA_ACTIONS {
    SET_CONTENTS = 'dataActions/setContents',
    SET_FEEDS = 'dataActions/setFeeds',
    SET_WEBINARS = 'dataActions/setWebinars',
    SET_TAGS = 'dataActions/setTags',
    SET_CATEGORIES = 'dataActions/setCategories',
    SET_GUESTS = 'dataActions/setGuests',
    SET_CATALOGS = 'dataActions/setCatalogs',
    SET_SPREAKER_SHOW = 'dataActions/setSpreakerShow',
    SET_SERVICES = 'dataActions/setServices',
    RESET_DATA = 'dataActions/resetData'
}

export const setContentsData: ReduxAction<Contents[]> = (content) => {
    return {
        type: DATA_ACTIONS.SET_CONTENTS,
        payload: content
    }
}

export const setFeedsData: ReduxAction<Feed[]> = (feeds) => {
    return {
        type: DATA_ACTIONS.SET_FEEDS,
        payload: feeds
    }
}

export const setWebinarsData: ReduxAction<Webinar> = (webinar) => {
    return {
        type: DATA_ACTIONS.SET_WEBINARS,
        payload: webinar
    }
}

export const setTagsData: ReduxAction<Tags> = (tags) => {
    return {
        type: DATA_ACTIONS.SET_TAGS,
        payload: tags
    }
}

export const setCategoriesData: ReduxAction<Categories> = (categories) => {
    return {
        type: DATA_ACTIONS.SET_CATEGORIES,
        payload: categories
    }
}

export const setGuestsData: ReduxAction<Guests> = (guests) => {
    return {
        type: DATA_ACTIONS.SET_GUESTS,
        payload: guests
    }
}

export const setCatalogsData: ReduxAction<Catalogs[]> = (catalogs) => {
    return {
        type: DATA_ACTIONS.SET_CATALOGS,
        payload: catalogs
    }
}

export const setSpreakerShowsData: ReduxAction<SpreakerShow[]> = (shows) => {
    return {
        type: DATA_ACTIONS.SET_SPREAKER_SHOW,
        payload: shows
    }
}

export const setServicesSubscriptions: ReduxAction<Service[]> = (services) => {
    return {
        type: DATA_ACTIONS.SET_SERVICES,
        payload: services
    }
}

export const resetData: ReduxAction<void> = () => {
    return {
        type: DATA_ACTIONS.RESET_DATA
    }
}
