import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { defaultProfileImage, version } from '../resources/constants'
import { Reducers, ReducerUser } from '../types/redurcers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { logoutUser } from '../store/actions/user'
import { useHistory } from 'react-router-dom'
import { resetData } from '../store/actions/data'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Header: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [showOptions, setShowOptions] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <div className="header">
            <ToastContainer />
            <div className="infoPanel">
                Versione {version} - Aiutaci a migliorare il servizio.
                <a onClick={() => window.open('mailto:info@22hbg.com?subject=Invio segnalazione bug Peperoni')}>Invia una segnalazione a info@22hbg.com</a>
            </div>
            <div className="userIcon" onClick={() => setShowOptions(!showOptions)}>
                <img
                    className="profileIconImage"
                    src={user.avatar !== undefined && user.avatar !== null && user.avatar !== '' ? user.avatar : defaultProfileImage}
                />
            </div>
            <div
                className="menuProfile"
                style={{
                    display: showOptions ? 'block' : 'none'
                }}
            >
                <p className="userMail">{user.email}</p>
                <NavLink to={'/profile'} onClick={() => setShowOptions(false)}>
                    <p className="userProfileButton">
                        <FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} /> Profilo
                    </p>
                </NavLink>

                <p
                    className="userProfileButton"
                    onClick={() => {
                        history.push('/')
                        dispatch(resetData())
                        dispatch(logoutUser())
                    }}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 10 }} /> Logout
                </p>
            </div>
        </div>
    )
}

export default Header
