import React, { useEffect } from 'react'
import './App.css'
import Sidebar from './components/sidebar/Sidebar'
import Footer from './components/Footer'
import { BrowserRouter, Route } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import { Reducers, ReducerUser } from './types/redurcers'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePage from './pages/Profile/ProfilePage'
import Header from './components/Header'
import ContentsTablePage from './pages/Contents/ContentsTablePage'
import ContentsDetailPage from './pages/Contents/ContentsDetailPage'
import WebinarsTablePage from './pages/Webinar/WebinarsTablePage'
import WebinarsDetailPage from './pages/Webinar/WebinarsDetailPage'
import CatalogPage from './pages/Webinar/CatalogPage'
import RegisterPage from './pages/RegisterPage'
import ForgotPassowrdPage from './pages/ForgotPasswordPage'
import { setDatas } from './store/actions/thunk_actions'
import ExternalFeedsTablePage from './pages/ExternalFeeds/ExternalFeedsTablePage'
import ExternalFeedsDetailPage from './pages/ExternalFeeds/ExternalFeedsDetailPage'

const App: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (user.id !== '') {
            dispatch(setDatas())
        }
    }, [])

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            {user.id == '' ? (
                <div className="container">
                    <Route exact path="/" component={LoginPage} />
                    <Route exact path="/register" component={RegisterPage} />
                    <Route exact path="/forgot-password" component={ForgotPassowrdPage} />
                </div>
            ) : (
                <div className="container">
                    <Sidebar />
                    <Header />
                    <Route exact path="/" component={ProfilePage} />
                    <Route exact path="/profile" component={ProfilePage} />
                    <Route exact path="/contents" component={ContentsTablePage} />
                    <Route exact path="/contents/detail" component={ContentsDetailPage} />
                    <Route exact path="/externalFeeds" component={ExternalFeedsTablePage} />
                    <Route exact path="/externalFeeds/detail" component={ExternalFeedsDetailPage} />
                    <Route exact path="/webinars" component={WebinarsTablePage} />
                    <Route exact path="/webinars/detail" component={WebinarsDetailPage} />
                    <Route exact path="/webinars/catalogs" component={CatalogPage} />

                    <Footer />
                </div>
            )}
        </BrowserRouter>
    )
}

export default App
