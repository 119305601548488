import { toast } from 'react-toastify'

export const ToastSuccess = (text: string): React.ReactText =>
    toast.dark(text, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    })

export const ToastLoading = (text: string): React.ReactText =>
    toast.dark(text, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        progress: undefined
    })

export const dismissToast = (): any => toast.dismiss()
