import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ChevronDown, ChevronRight } from 'react-feather'
import { Badge } from 'reactstrap'
import { MenuItem } from '../../../types/graphic'

interface Props {
    name: string
    badge: any
    menuItems: MenuItem[]
}

const SideMenu: React.FC<Props> = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const singleItem = (name: string, badge: string, menuItems: MenuItem[]) => {
        return (
            <li className="nav-item">
                {menuItems[0].active ? (
                    <NavLink
                        to={menuItems[0].slug}
                        //@ts-ignore
                        exact
                        className="main navigateLink"
                        activeClassName="active"
                    >
                        <Badge color="#fff">{badge}</Badge>
                        <span className="item">{name}</span>
                    </NavLink>
                ) : null}
            </li>
        )
    }
    const multiItems = (name: string, badge: any, menuItems: MenuItem[]) => {
        return (
            <li className={isCollapsed ? 'nav-item collapsed' : 'nav-item'}>
                <a
                    className="main"
                    onClick={() => {
                        setIsCollapsed(!isCollapsed)
                    }}
                >
                    <Badge color="#fff">{badge}</Badge>
                    <span className="item">{name}</span>
                    <span className="item-arrow">{isCollapsed ? <ChevronDown size={16} /> : <ChevronRight size={16} />}</span>
                </a>
                <ul className={isCollapsed ? 'submenu show' : 'submenu hide'}>
                    {menuItems.map((item) => {
                        return (
                            <NavLink key={`m${item.name}`} to={item.active ? item.slug : '#'} className="navigateLink" activeClassName="active">
                                <span className="menu-item-text">{item.name}</span>
                            </NavLink>
                        )
                    })}
                </ul>
            </li>
        )
    }
    if (props.menuItems.length > 1) {
        return multiItems(props.name, props.badge, props.menuItems)
    } else {
        return singleItem(props.name, props.badge, props.menuItems)
    }
}

export default SideMenu
