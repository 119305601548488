import moment from 'moment'
import React from 'react'

const Footer: React.FC = () => (
    <footer>
        <div className="container-fluid">
            <p className="text-center">
                © {moment().format('YYYY')}
                <a
                    style={{
                        paddingLeft: 8
                    }}
                    href="https://22hbg.com"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    22HBG (info@22hbg.com)
                </a>
            </p>
        </div>
    </footer>
)

export default Footer
