import React from 'react'
import moment from 'moment'
import { Check } from '@material-ui/icons'

export const columns = [
    {
        title: 'Url',
        field: 'feedUrl'
    },
    {
        title: 'Attivo',
        field: 'active',
        // eslint-disable-next-line react/display-name
        render: (rowData: any) => {
            if (rowData.active === false) {
                return null
            }
            return <Check />
        }
    },
    {
        title: 'Ultima sincronizzazione',
        field: 'lastSync',
        // eslint-disable-next-line react/display-name
        render: (rowData: any) => {
            if (rowData.lastSync === null) {
                return <p>--</p>
            }

            return <p>{moment(rowData.lastSync).format('LLL')}</p>
        }
    },
    {
        title: 'Ultima modifica',
        field: 'lastUpdate',
        // eslint-disable-next-line react/display-name
        render: (rowData: any) => <p>{moment(rowData.lastUpdate).format('LLL')}</p>
    }
]
