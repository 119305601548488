import React, { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerUser } from '../../types/redurcers'
import './Contents.css'
import MaterialTable from 'material-table'
import { columns } from './ContentsTableColumns'
import { fromCategoriesToString, fromGuestToString, fromTagsToString, showsForSelect } from '../../utility/functions'
import { locale_itIT } from '../../utility/tableLocale'
import { Contents } from '../../types/data'
import CustomAxios from '../../customComponents/customAxios'
import { deleteContent, importToSpreaker, removeFromSpreaker } from '../../resources/constants'
import { setContents } from '../../store/actions/thunk_actions'
import addSpreaker from '../../resources/images/icons/spreaker_ok.png'
import removeSpreaker from '../../resources/images/icons/spreaker_nok.png'
import Select from 'react-select'
import Services from '../../utility/Services'
import ServiceActivation from '../../components/ServiceActivation'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faVideo, faAlignLeft, faPen, faTrash, faCopy, faImage } from '@fortawesome/free-solid-svg-icons'
import { ToastSuccess } from '../../utility/Toast'

interface Props {
    history: any
}

const ContentsTablePage: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
    const [selectedContent, setSelectedContent] = useState<Contents | undefined>()
    const [visibleAddToSpreaker, setVisibleAddToSpreaker] = useState(false)
    const [visibleRemoveFromSpreaker, setVisibleRemoveFromSpreaker] = useState(false)
    const [visibleModalActions, setVisibleModalActions] = useState(false)
    const dispatch = useDispatch()
    const selectedShow = useRef(null)

    const newContent: Contents = {
        id: -1,
        title: '',
        description: '',
        audioUrl: '',
        videoUrl: '',
        txt: '',
        txtUrl: '',
        imgUrl: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        tags: [],
        categories: [],
        guests: [],
        language: '',
        isPublic: true,
        spreakerEpisodeId: ''
    }

    const handleAudio = (audio: Contents) => {
        //console.log("FILE AUDIO", audio);
        const contentPlayer = {
            id: audio.id,
            title: audio.title,
            date: audio.createdAt,
            description: audio.description,
            author: user.firstName + ' ' + user.lastName,
            audio_url: audio.audioUrl,
            video_url: '',
            img_url: audio.imgUrl !== null ? audio.imgUrl : user.avatar
        }
        //dispatch(setContentPlayer(contentPlayer))
        window.open('https://console.peperoni.ai/single-player?userId=' + user.id + '&idContent=' + audio.id, '_blank')
    }

    const handleVideo = (video: Contents) => {
        const contentPlayer = {
            id: video.id,
            title: video.title,
            date: video.createdAt,
            description: video.description,
            author: user.firstName + ' ' + user.lastName,
            audio_url: '',
            video_url: video.videoUrl,
            img_url: video.imgUrl !== null ? video.imgUrl : user.avatar
        }
        //dispatch(setContentPlayer(contentPlayer))
        window.open('https://console.peperoni.ai/single-player?userId=' + user.id + '&idContent=' + video.id, '_blank')
    }

    const handleEdit = (id: number) => {
        const content = data.contents.find((item) => item.id == id)
        props.history.push('/contents/detail', { content: content, isNew: false })
    }

    const handleDelete = async (contentId: number) => {
        await CustomAxios.delete(deleteContent(user.id, contentId)).then(async () => await dispatch(setContents(user.id)))
        setVisibleDeleteModal(false)
        setVisibleModalActions(false)
        setSelectedContent(undefined)
    }

    const handleCopy = (content: Contents) => {
        const stringToCopy = 'https://console.peperoni.ai/smallplayer?token=' + user.id + '&idContent=' + content.id + '&colors=0d3371,1ea3dd'
        console.log('TEST COPY', stringToCopy)
        navigator.clipboard.writeText(stringToCopy)
        ToastSuccess('📄 URL copiato negli appunti')
    }

    const renderModalAddSpreaker = () => {
        return (
            <Dialog open={visibleAddToSpreaker} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Stai importando il contenuto su Spreaker</DialogTitle>
                <DialogContent style={{ height: 400 }}>
                    <div>
                        <Select
                            ref={selectedShow}
                            id="select_spreaker_show"
                            name="spreaker_show"
                            placeholder="Seleziona uno show"
                            options={showsForSelect(data.spreakerShows)}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleAddToSpreaker(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={async () => {
                                //@ts-ignore
                                const showId = selectedShow.current.state.value.id
                                if (selectedContent !== undefined) {
                                    await CustomAxios.get(importToSpreaker(user.id, showId, selectedContent?.id)).then(async () => {
                                        await dispatch(setContents(user.id))
                                    })
                                    setVisibleAddToSpreaker(false)
                                    setVisibleModalActions(false)
                                    setSelectedContent(undefined)
                                }
                            }}
                            color="secondary"
                        >
                            Importa
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const renderRemoveSpreaker = () => {
        return (
            <Dialog open={visibleRemoveFromSpreaker} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Stai rimuovendo il contenuto da Spreaker</DialogTitle>
                <DialogContent>
                    <DialogContentText>Stai eliminando {selectedContent?.title} da Spreaker. Vuoi continuare?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleRemoveFromSpreaker(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={async () => {
                                //@ts-ignore
                                await CustomAxios.get(removeFromSpreaker(user.id, selectedContent?.spreakerEpisodeId)).then(async () => {
                                    await dispatch(setContents(user.id))
                                })
                                setVisibleRemoveFromSpreaker(false)
                                setVisibleModalActions(false)
                                setSelectedContent(undefined)
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const renderActionsModal = () => {
        return (
            <Dialog fullWidth={true} open={visibleModalActions} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{selectedContent?.title}</DialogTitle>
                <DialogContent>
                    <div className="actionsList">
                        {selectedContent !== undefined && selectedContent?.audioUrl !== null && (
                            <div
                                className="action audio"
                                onClick={() => {
                                    handleAudio(selectedContent)
                                }}
                            >
                                <FontAwesomeIcon icon={faMusic} style={{ marginRight: 20 }} />
                                Riproduci anteprima audio
                            </div>
                        )}
                        {selectedContent !== undefined && selectedContent?.videoUrl !== null && (
                            <div
                                className="action video"
                                onClick={() => {
                                    handleVideo(selectedContent)
                                }}
                            >
                                <FontAwesomeIcon icon={faVideo} style={{ marginRight: 20 }} />
                                Riproduci anteprima video
                            </div>
                        )}
                        {selectedContent !== undefined && selectedContent?.txtUrl !== null && (
                            <div
                                className="action text"
                                onClick={() => {
                                    window.open(selectedContent.txtUrl)
                                }}
                            >
                                <FontAwesomeIcon icon={faAlignLeft} style={{ marginRight: 20 }} />
                                Visualizza anteprima testo
                            </div>
                        )}
                        {selectedContent !== undefined && selectedContent?.imgUrl !== null && (
                            <div
                                className="action img"
                                onClick={() => {
                                    window.open(selectedContent.imgUrl)
                                }}
                            >
                                <FontAwesomeIcon icon={faImage} style={{ marginRight: 20 }} />
                                Visualizza immagine
                            </div>
                        )}
                        {selectedContent !== undefined && (
                            <div
                                className="action edit"
                                onClick={() => {
                                    handleEdit(selectedContent.id)
                                }}
                            >
                                <FontAwesomeIcon icon={faPen} style={{ marginRight: 20 }} />
                                Modifica contenuto
                            </div>
                        )}
                        {selectedContent !== undefined && (
                            <div
                                className="action remove"
                                onClick={() => {
                                    setVisibleDeleteModal(true)
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} style={{ marginRight: 20 }} />
                                Elimina contenuto
                            </div>
                        )}
                        {selectedContent !== undefined && (selectedContent.audioUrl !== null || selectedContent.videoUrl !== null) && (
                            <div
                                className="action copy"
                                onClick={() => {
                                    handleCopy(selectedContent)
                                }}
                            >
                                <FontAwesomeIcon icon={faCopy} style={{ marginRight: 20 }} />
                                Copia URL player
                            </div>
                        )}
                        {user.hasSpreaker &&
                            selectedContent !== undefined &&
                            (selectedContent.spreakerEpisodeId !== null ? (
                                <div
                                    className="action removespreaker"
                                    onClick={() => {
                                        setVisibleRemoveFromSpreaker(true)
                                    }}
                                >
                                    <img height="18px" width="18px" style={{ marginRight: 15 }} src={removeSpreaker} />
                                    Rimuovi da Spreaker
                                </div>
                            ) : (
                                <div
                                    className="action addspreaker"
                                    onClick={() => {
                                        setVisibleAddToSpreaker(true)
                                    }}
                                >
                                    <img height="18px" width="18px" style={{ marginRight: 15 }} src={addSpreaker} />
                                    Aggiungi a Spreaker
                                </div>
                            ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleModalActions(false)
                                setSelectedContent(undefined)
                            }}
                            color="secondary"
                        >
                            Chiudi
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    const renderData = useMemo(() => {
        if (data.contents === undefined) {
            return []
        }

        return data.contents.map((content) => {
            return {
                ...content,
                categories: fromCategoriesToString(content.categories),
                tags: fromTagsToString(content.tags),
                guests: fromGuestToString(content.guests)
            }
        })
    }, [data.contents])

    const renderActions = useMemo(() => {
        const actions = [
            () => ({
                icon: 'more_horiz',
                iconProps: {
                    color: 'primary',
                    fontSize: 'large'
                },
                tooltip: 'Menu',
                onClick: (event: any, rowData: any) => {
                    setVisibleModalActions(true)
                    setSelectedContent(rowData)
                }
            })
            // (rowData: any) => ({
            //     icon: 'audiotracktwotone',
            //     iconProps: {
            //         color: rowData.audioUrl !== null ? 'primary' : 'disabled'
            //     },
            //     tooltip: 'Audio',
            //     onClick: (event: any, rowData: any) => handleAudio(rowData),
            //     disabled: rowData.audioUrl == null
            // }),
            // (rowData: any) => ({
            //     icon: 'theaters',
            //     iconProps: {
            //         color: rowData.videoUrl !== null ? 'secondary' : 'disabled'
            //     },
            //     tooltip: 'Video',
            //     onClick: (event: any, rowData: any) => handleVideo(rowData),
            //     disabled: rowData.videoUrl == null
            // }),
            // (rowData: any) => ({
            //     icon: 'subject',
            //     iconProps: {
            //         color: rowData.textUrl !== null ? 'secondary' : 'disabled'
            //     },
            //     tooltip: 'Testo',
            //     onClick: (event: any, rowData: any) => window.open(rowData.txtUrl),
            //     disabled: rowData.textUrl == null
            // }),

            // {
            //     icon: 'edit',
            //     tooltip: 'Modifica',
            //     onClick: (event: any, rowData: any) => handleEdit(rowData.id)
            // },
            // {
            //     icon: 'delete',
            //     iconProps: { color: 'error' },
            //     tooltip: 'Elimina',
            //     onClick: (event: any, rowData: any) => {
            //         setVisibleDeleteModal(true)
            //         setSelectedContent(rowData)
            //     }
            // },
            // (rowData: any) => ({
            //     icon: 'content_copy',
            //     iconProps: {
            //         color: rowData.audioUrl !== null || rowData.videoUrl !== null ? 'action' : 'disabled'
            //     },
            //     tooltip: 'Copia URL Player',
            //     onClick: (event: any, rowData: any) => handleCopy(rowData),
            //     disabled: rowData.audioUrl == null
            // })
        ]

        // if (user.hasSpreaker) {
        //     //@ts-ignore
        //     actions.push((rowData: any) => ({
        //         // eslint-disable-next-line react/display-name
        //         icon: () => <img height="22px" width="22px" src={rowData.hasSpreaker !== null ? removeSpreaker : addSpreaker} />,
        //         tooltip: rowData.hasSpreaker !== null ? 'Rimuovi da Spreaker' : 'Importa su Spreaker',
        //         onClick: () => {
        //             if (rowData.hasSpreaker == null) {
        //                 //handleAddToSpreaker(rowData.id)
        //                 setVisibleAddToSpreaker(true)
        //                 setSelectedContent(rowData)
        //             } else {
        //                 //handleRemoveFromSpreaker(rowData.id)
        //                 setVisibleRemoveFromSpreaker(true)
        //                 setSelectedContent(rowData)
        //             }
        //         }
        //     }))
        // }

        return actions
    }, [])

    const renderModalDelete = () => {
        return (
            <Dialog open={visibleDeleteModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Vuoi cancellare questo contenuto?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Stai eliminando {selectedContent?.title}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            onClick={() => {
                                setVisibleDeleteModal(false)
                            }}
                            color="primary"
                        >
                            Annulla
                        </Button>
                        <Button
                            onClick={() => {
                                //@ts-ignore
                                handleDelete(selectedContent?.id)
                            }}
                            color="secondary"
                        >
                            Elimina
                        </Button>
                    </>
                </DialogActions>
            </Dialog>
        )
    }

    if (!user.services.includes(Services.Contents)) {
        return <ServiceActivation service={Services.Contents} />
    }

    return (
        <div className="contentsTablePage">
            <div className="contentsTitleSection">
                <h2>Elenco dei contenuti caricati</h2>
                <p>I tuoi contenuti presenti nel database</p>
            </div>
            <a
                className="contentsButton"
                onClick={() => {
                    props.history.push('/contents/detail', {
                        content: newContent,
                        isNew: true
                    })
                }}
            >
                Aggiungi nuovo
            </a>
            {/*eslint-disable-next-line react/jsx-no-target-blank */}
            <a className="previewButton" href={'https://console.peperoni.ai/fullplayer/?token=' + user.id} target="_blank">
                Il tuo player (beta)
            </a>
            <div style={{ clear: 'both' }}></div>
            <div className="contentsTableSection">
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderData}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50]
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            {renderModalDelete()}
            {renderModalAddSpreaker()}
            {renderRemoveSpreaker()}
            {renderActionsModal()}
        </div>
    )
}

export default ContentsTablePage
