import { PLAYER_ACTIONS } from '../actions/player'
import { ReducerGraphic, ReduxActionData } from '../../types/redurcers'

const defaultValue: ReducerGraphic = {
    contentPlayer: {
        id: -1,
        title: '',
        date: new Date(),
        description: '',
        author: '',
        audioUrl: '',
        videoUrl: '',
        imgUrl: ''
    }
}

const graphicState: (state: ReducerGraphic, action: ReduxActionData<any>) => ReducerGraphic = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case PLAYER_ACTIONS.SET_CONTENT_PLAYER:
            return {
                ...state,
                contentPlayer: action.payload
            }
        default:
            return state
    }
}

export default graphicState
